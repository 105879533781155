import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import imgProfile from "../../assets/imgProfile.png";
import style from './CenteredModal.module.css'

export default function CenteredModal(props) {

    useEffect(
        ()=>{
            console.log('props modal')
            console.log(props)
        },[props]
    )
    return (

        <Modal
            {...props}
            size="lg"
            onHide={()=> {
                props.onHide()
                props.setDoc(null)
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {/*<Modal.Header closeButton>*/}
            {/*    <Modal.Title id="contained-modal-title-vcenter">*/}
            {/*        {props.props.fullname}*/}
            {/*    </Modal.Title>*/}
            {/*</Modal.Header>*/}
            <Modal.Body className="p-2">
                {/*{props.props.description}*/}
                {props.doctor !== undefined &&
                <Container
                    className="g-2 d-flex flex-row flex-wrap justify-content-start align-content-start m-0">
                    <Col xs={12} md={6} xl={4} className="d-flex flex-row justify-content-center p-1 g-0 m-0 mb-2">
                        <Card className=" shadow-none ">
                            <Card.Body className={`customPaddingImg mb-0 py-0`}>
                                <Container className="px-0">
                                    <Row>
                                        <Col xs={5} className={style.profileCol}>
                                            <Image className={style.profileCol}
                                                   style={{borderRadius:"10px"}}
                                                   src={props.doctor.image !== null ? props.doctor.image : imgProfile}/>
                                        </Col>
                                        <Col className=" ps-4">
                                            <Card.Title className="m-0">
                                                Dr. {  props.doctor.fullname}
                                            </Card.Title>
                                            <p style={{fontSize: "0.764rem"}} className="g-1 m-0">
                                                { props.medfield.find(elm => elm.id === props.doctor.medicalField).name}
                                            </p>
                                            <p>
                                                <svg width="41" height="18" viewBox="0 0 41 18" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="41" height="18" rx="2.22642" fill="#F3E8E8"/>
                                                    <path
                                                        d="M12.7059 10.7567C12.5656 10.8927 12.5011 11.0893 12.5331 11.2822L13.0146 13.9472C13.0552 14.173 12.9599 14.4016 12.7709 14.5322C12.5856 14.6676 12.3391 14.6838 12.1371 14.5755L9.73806 13.3242C9.65465 13.2798 9.56202 13.256 9.46723 13.2533H9.32044C9.26952 13.2609 9.21969 13.2771 9.17419 13.302L6.7746 14.5592C6.65598 14.6188 6.52165 14.64 6.39002 14.6188C6.06935 14.5582 5.8554 14.2527 5.90794 13.9304L6.39002 11.2654C6.42198 11.0709 6.35752 10.8732 6.21723 10.7351L4.26127 8.83925C4.09769 8.68054 4.04081 8.44221 4.11556 8.22716C4.18815 8.01266 4.3734 7.85612 4.5971 7.82091L7.28919 7.43037C7.49394 7.40925 7.67377 7.28466 7.76585 7.1005L8.9521 4.66841C8.98027 4.61425 9.01656 4.56441 9.06044 4.52216L9.10919 4.48425C9.13465 4.45608 9.1639 4.43279 9.1964 4.41383L9.25544 4.39216L9.34752 4.35425H9.57556C9.77923 4.37537 9.95852 4.49725 10.0522 4.67925L11.2542 7.1005C11.3409 7.27762 11.5093 7.40058 11.7038 7.43037L14.3959 7.82091C14.6234 7.85341 14.8135 8.0105 14.8888 8.22716C14.9597 8.44437 14.8985 8.68271 14.7317 8.83925L12.7059 10.7567Z"
                                                        fill="#C13130"/>
                                                    <path
                                                        d="M20.6562 12.2955V11.2301L24.4276 5.27273H25.267V6.84091H24.7344L22.0369 11.1108V11.179H27.206V12.2955H20.6562ZM24.794 14V11.9716L24.8026 11.4858V5.27273H26.0511V14H24.794ZM30.0376 12.8068L29.9737 13.2713C29.9311 13.6122 29.8587 13.9673 29.7564 14.3366C29.657 14.7088 29.5533 15.054 29.4453 15.3722C29.3402 15.6903 29.2536 15.9432 29.1854 16.1307H28.282C28.3189 15.9545 28.37 15.7159 28.4354 15.4148C28.5007 15.1165 28.5646 14.7827 28.6271 14.4134C28.6896 14.044 28.7365 13.6676 28.7678 13.2841L28.8104 12.8068H30.0376ZM31.8913 14L35.701 6.46591V6.40199H31.2947V5.27273H37.0646V6.44034L33.2678 14H31.8913Z"
                                                        fill="#C13130"/>
                                                </svg>
                                            </p>
                                            <p style={{fontSize: "0.764rem"}}>
                                                {props.doctor.practicYear} il təcrübə
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Card.Text style={{maxHeight: "100px", fontSize: "0.764rem",overflow: "auto"}}>
                                            {props.doctor.description}
                                        </Card.Text>
                                    </Row>
                                    <Row>
                                        <Col className="d-flex flex-row justify-content-end mt-3 mb-0">
                                            <Button

                                                onClick={()=>{
                                                    props.shareWpDoctorModal(props.doctor.id)
                                                }}
                                                className="customDangerColor mb-0"
                                                    variant="danger">
                                                {props.navbarByLang.body.doctors.getInformation}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>

                </Container>

                }

            </Modal.Body>
            {/*<Modal.Footer>*/}
            {/*    <Button onClick={props.onHide}>Close</Button>*/}
            {/*</Modal.Footer>*/}
        </Modal>
    );
}