import Image from "react-bootstrap/Image";
import imgWp from "../assets/imgWhatsupLogo.png";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import appLogo from "../assets/appLogo.png";
import {
    MDBAccordion, MDBAccordionItem,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBIcon,

} from "mdb-react-ui-kit";
import menuIcon from "../assets/mobile/imgMenuLogo.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import Row from "react-bootstrap/Row";
import imgLogoMini from "../assets/mobile/appLogoMini.png";
import Button from "react-bootstrap/Button";
import imgCloseButton from "../assets/mobile/imgCloseButton.png";
import imgMenuDoctors from "../assets/mobile/imgMenuDoctors.png";
import imgMenuServices from "../assets/mobile/imgMenuServices.png";
import imgMenuServicesBig from "../assets/mobile/imgMenuServicesBig1.png";
import imgMenuAboutUs from "../assets/mobile/imgMenuAboutUs.png";
import imgMenuAboutUsBig from "../assets/mobile/imgMenuAboutUsBig1.png";
import imgMenuContacts from "../assets/mobile/imgMenuContacts.png";
import imgMenuContactsBig from "../assets/mobile/imgMenuContactsBig1.png";
import imgMenuShare from "../assets/mobile/imgMenuShare.png";
import imgMenuShare2 from "../assets/mobile/imgMenuShare2.png";
import imgMenuShare3 from "../assets/mobile/imgMenuShare3.png";
import imgSlider from "../assets/mobile/imgSliderMobile.png";
import Card from "react-bootstrap/Card";
import imgProfile from "../assets/imgProfile.png";
// import imgDownloadFileBtn from "../assets/imgDownloadFileBtn.png";
// import imgSocar from "../assets/imgSocarLogo.png";
// import imgITS from "../assets/imgITSLogo.png";
// import imgAAC from "../assets/imgAACSSLogo.png";
// import imgMega from "../assets/imgMegaLogo.png";
// import imgAtewgah from "../assets/imgAteshgahLogo.png";
// import imgPasha from "../assets/imgPashaLogo.png";
import imgMedicSchool from "../assets/imgMedicSchoolLogo.png";
import imgSosialsALL from "../assets/mobile/imgSosialsMobile.png";
import imgDownloadAppMobile from "../assets/mobile/imgDownloadAppMobile.png";

import imgPlayStore from "../assets/imgPlayStore.png";
import {useMediaQuery} from "react-responsive";
import React, {useState, useEffect} from "react";
import style from './Mobile.module.css';
import CenteredModal from "../components/CenteredModal/CenteredModal";
import CenteredModalSOS from "../components/CenteredModalSOS/CenteredModalSOS";

const Mobile = ({languagesArr,doctorList,simptomsList,medicalFieldList,activeFieldIdList,setActiveFieldIdList,changeIsChild,isChild,
                    activeSimptomsIdList,
                    setActiveSimptomsIdList,
                    activeSimptomCount,
                    setActiveSimptomsCount,
                    setMedicalFieldList,
                    setdDoctorList,
                    getCurrentDoctorData,
                    openModalDoctor,
                    shareWpDoctorModal,
                    modalDoctor,
                    setModalDoctor,
                    setDoc,
                    changeLanguage,
                    setLang,
                    lang,
                    navbarByLang,
                    showModalSOS,
                    setShowModalSOS,
                    reLoadPage
                    // allDoctorsNotActive,
                    // allDoctorsActive,
                    // threeDoctorsForAboutUs,
                    // fourDoctorsForAboutUs
                }) => {
    const isPortraitMinSizeXs = useMediaQuery({query: '(max-width: 320px)'})
    const isPortraitMinSize = useMediaQuery({query: '(max-width: 360px)'})
    const isPortraitMidSize = useMediaQuery({query: '(max-width: 575.98px)'})
    const [isChevronRight1, setIsChevronRight1] = useState(true)
    const [isChevronRight2, setIsChevronRight2] = useState(true)
    const [isChevronRight3, setIsChevronRight3] = useState(true)
    const [isChevronRight4, setIsChevronRight4] = useState(true)
    const [isChevronRight5, setIsChevronRight5] = useState(true)

    // const [medicalFieldList,setMedicalFieldList]=useState(medicalFieldListAll)
    // useIsomorphicLayoutEffect(() => {
    //     const matchMedia = window.matchMedia('max-width: 360px')
    //
    //     // Triggered at the first client-side load and if query changes
    //     handleFirstImageCall()
    //
    //     // Use deprecated `addListener` and `removeListener` to support Safari < 14 (#135)
    //     if (matchMedia.addListener) {
    //         matchMedia.addListener(handleChange)
    //     } else {
    //         matchMedia.addEventListener('change', handleChange)
    //     }
    //
    //     return () => {
    //         if (matchMedia.removeListener) {
    //             matchMedia.removeListener(handleChange)
    //         } else {
    //             matchMedia.removeEventListener('change', handleChange)
    //         }
    //     }
    // }, ['max-width: 360px'])
    // const updateMedicalFieldList = ()=>{
    //     setMedicalFieldList(medicalFieldListAll)
    // }
    // useEffect(()=>{
    //     updateMedicalFieldList()
    // },[medicalFieldListAll])
    const changeChevronPosition = (chevronNumber) => {
        if (chevronNumber === 1) {
            setIsChevronRight1(!isChevronRight1)
            setIsChevronRight2(true)
            setIsChevronRight3(true)
            setIsChevronRight4(true)
            setIsChevronRight5(true)
        }
        if (chevronNumber === 2) {
            setIsChevronRight2(!isChevronRight2)
            setIsChevronRight1(true)
            setIsChevronRight3(true)
            setIsChevronRight4(true)
            setIsChevronRight5(true)
        }
        if (chevronNumber === 3) {
            setIsChevronRight3(!isChevronRight3)
            setIsChevronRight1(true)
            setIsChevronRight2(true)
            setIsChevronRight4(true)
            setIsChevronRight5(true)
        }
        if (chevronNumber === 4) {
            setIsChevronRight4(!isChevronRight4)
            setIsChevronRight1(true)
            setIsChevronRight2(true)
            setIsChevronRight3(true)
            setIsChevronRight5(true)
        }
        if (chevronNumber === 5) {
            setIsChevronRight5(!isChevronRight5)
            setIsChevronRight1(true)
            setIsChevronRight2(true)
            setIsChevronRight3(true)
            setIsChevronRight4(true)
        }
    }
    const wpNumber = '994702666510'
    // const [lang, setLang] = useState('AZ')
    let selectedLangData = languagesArr.find(el => el.langKey === lang).langValues

    // const [navbarByLang, setNavbarByLang] = useState(selectedLangData)
    // const changeLanguage = (lang) => {
    //     setLang(lang)
    //     setNavbarByLang(changePageLangValues(lang))
    // }
    const changePageLangValues = (lang) => {
        return languagesArr.find(el => el.langKey === lang).langValues;
    }
    // const [activeSimptomsIdList, setActiveSimptomsIdList] = useState([])
    // const [activeSimptomCount, setActiveSimptomsCount ] = useState(0)

    // const [activeFieldIdList, setActiveFieldIdList] = useState([])
    const [isAllDoctors, setIsAllDoctors] = useState(false)

    const checkIsSimptomSelected =(id)=>{
        if(!activeSimptomsIdList.includes(id))
        {
            setActiveSimptomsIdList([...activeSimptomsIdList, id])
        }
        else {
            setActiveSimptomsIdList(activeSimptomsIdList.filter(sId => sId !== id))
        }
    }
    const checkIsMedFieldClicked =(id)=>{

        if(!activeFieldIdList.includes(id))
        {
            setActiveFieldIdList([...activeFieldIdList, id])
        }
        else {
            setActiveFieldIdList(activeFieldIdList.filter(sId => sId !== id))
        }
    }
    const checkIsFieldSelected = () => {
        if (activeSimptomsIdList.length > 0)
            for (let i = 0; i < activeSimptomsIdList.length; i++) {
                let simptMedFieldArr = simptomsList.find(el => el.id === activeSimptomsIdList[i]).medId
                if (activeFieldIdList.length === 0) {
                    setActiveFieldIdList(simptMedFieldArr)
                } else {
                    if(activeSimptomCount > activeSimptomsIdList.length){
                        setActiveFieldIdList(simptMedFieldArr)
                    }else
                        setActiveFieldIdList([...simptMedFieldArr, ...activeFieldIdList.filter(el => !simptMedFieldArr.includes(el))])
                }
            } else {
            setActiveFieldIdList([])
        }
    }
    const setCountMedFields=()=>{
        setActiveSimptomsCount(activeSimptomsIdList.length)
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const getAllDocs =()=>{
        setIsAllDoctors(!isAllDoctors)
    }

    let sharedDataSample = {
        text: "Sağlıkçını paylaş",
        url: "https://saglikci.az"
    }
    // const [sharedData,setSharedData] = useState(sharedDataSample)


    useEffect(()=>{
        setCountMedFields()
        checkIsFieldSelected()
    },[activeSimptomsIdList])
    useEffect(()=>{
        if(activeFieldIdList.length ===0 && activeSimptomsIdList.length !==0) {
            setActiveSimptomsIdList([])
        }
         //
         // console.log("activeFieldIdList.length - " + activeFieldIdList.length)
         // console.log("activeSimptomsIdList.length - "+ activeSimptomsIdList.length)
         //

    },[activeFieldIdList])

    const allSimptoms = simptomsList.length > 0 && simptomsList.map(el =>
        <Button key={el.id}
                // className={`${style.customButtonSize}
                //                     ${activeSimptomsIdList.includes(el.id) ? style.btnRed : style.btnGrey}
                //                     rounded-9 text-nowrap shadow-none `}
                className={`${style.customButtonSimptom} 
                                   ${activeSimptomsIdList.includes(el.id) ? style.btnRedSimptom :  style.btnGreySimptom}  
                                    rounded-3 text-wrap text-align-left shadow-none btn-light  `}
            onClick={()=> {
                checkIsSimptomSelected(el.id)
            }}
        >
            <Container className={style.btnSimptom1}>
                <Row style={{marginTop:'35px'}}>
                   <span className={style.fontSimptomButtons} style={{whiteSpace:'pre-wrap'}}>
                        {el.name}
                    </span>
                </Row>
                <Row>
                    <Col className='d-flex flex-row justify-content-center align-items-center h-100' style={{
                        marginLeft:"50px"
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                             className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                            <path fillRule="evenodd"
                                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
                        </svg>
                    </Col>
                </Row>
            </Container>
            {/*"+<br>+"*/}
            {/*{el.name.includes('a') ? el.name.split(' ').find(el=>el) :el.name}*/}
        </Button>)

    const allMedicalActiveField = medicalFieldList.length > 0
        && medicalFieldList.map(el => {
            if (activeFieldIdList.includes(el.id))
                return (
                    <Button key={el.id}
                            className={`${style.customButtonSize} ${style.btnRed} rounded-3 text-nowrap shadow-none `}
                            onClick={
                                () => {
                                    checkIsMedFieldClicked(el.id)
                                    // onlyMedField()
                                }
                            }
                    >
                        {el.name}
                    </Button>)
        })
    const allMedicalDeactiveField = medicalFieldList.length > 0
        && medicalFieldList.map(el => {
            if (!activeFieldIdList.includes(el.id))
                return (
            <Button key={el.id}
                    className={`${style.customButtonSize }  ${style.btnGrey} rounded-3 text-nowrap shadow-none `}
                    onClick={
                        ()=>{
                            checkIsMedFieldClicked(el.id)
                            // onlyMedField()
                        }
                    }
            >
                {el.name}
            </Button>)
        })

    const spliterFullName = (fullname)=>{
        let name = fullname.split(' ')[0]
        let surname = fullname.split(' ')[1]
        return name +' '+ surname[0]
    }
    // const openModalDoctor =( el)=>{
    //     console.log(el.target.id)
    //     let doctor = getCurrentDoctorData(parseInt(el.target.id))
    //     setDoc(doctor)
    // }
    const shareWpDoctor =( el)=>{
        // debugger
        let doctor = getCurrentDoctorData(parseInt(el.currentTarget.id))
        let urlEnText = encodeURIComponent('Salam. Həkim '+doctor.fullname + ' haqqında məlumat almaq istəyirəm')
        let sharedDataSample={
            text: doctor.fullname,
            url: 'https://wa.me/994702666511?text='+urlEnText
        }
        window.location.href= sharedDataSample.url;
    }
    // const shareWpDoctorModal =(el)=>{
    //     let doctor = getCurrentDoctorData(parseInt(el))
    //     let urlEnText = encodeURIComponent('Salam. Həkim '+doctor.fullname + ' haqqında məlumat almaq istəyirəm')
    //     let sharedDataSample={
    //         text: doctor.fullname,
    //         url: 'https://wa.me/994702666511?text='+urlEnText
    //     }
    //     window.location.href =  sharedDataSample.url;
    // }
    //
    // const getCurrentDoctorData =(id)=>{
    //     let doctor ={}
    //     if(doctorList.length > 0) doctor = doctorList.find(el=> el.id === id)
    //     return doctor
    // }

    useEffect(()=>{
        setModalShow(true)
        // console.log(modalDoctor)
    },[modalDoctor])
    useEffect(()=>{
        // debugger
        console.log(doctorList)
    },[])

    let allDoctorsNotActive =  doctorList.length > 0 && doctorList.map(el =>
        <Col key={el.id}
             id={parseInt(el.id)}
             onClick={
                 (el) => {
                     openModalDoctor( el)
                 }}
             xs={4}
             style={{
                 transition: 'all  0.2s ease-out', cursor:'pointer'
             }}
             className={`d-flex flex-row flex-column nowrap justify-content-start g-0 me-1 
                    ${style.doctorsCardsOut}
                  ${activeFieldIdList.length !==0 && (!activeFieldIdList.includes(el.medicalField) && ' d-none ')}`}
             >
            <Card key={el.id}  id={parseInt(el.id)}
                  onClick={
                      (el) => {
                          openModalDoctor( el)
                      }}
                  className={`${style.doctorsCards} shadow-none`}>
                <Card.Body id={parseInt(el.id)}  className={`${style.customPaddingImg} mb-0`}>
                    <Container   id={parseInt(el.id)}  className="">
                        <Row   id={parseInt(el.id)}  className=''>
                            <Col id={parseInt(el.id)}  xs={5} className='  w-auto'>
                                <Col id={parseInt(el.id)}  xs={12} className={` d-flex flex-row justify-content-center`}>
                                    <Image id={parseInt(el.id)}  className={`${style.profileCol}`} src={el.image !== null ? el.image : imgProfile}/>
                                </Col>
                                <Col id={parseInt(el.id)}  xs={12} className="">
                                    <Card.Title id={parseInt(el.id)}  className="m-0 p-0 g-0" style={{minHeight: "20px", height:'auto'}}>
                                        <p id={parseInt(el.id)}  style={{
                                            fontWeight: '700',
                                            fontSize: '12px',
                                            // margin: 10px 0px;
                                            whiteSpace: 'nowrap',
                                            height: '18px',
                                            lineHeight: '18px',
                                            marginBottom: '0px',
                                            fontFamily: `'Poppins', sans-serif`
                                        }}>Dr. {spliterFullName(el.fullname)}.</p>
                                    </Card.Title>
                                    <Col  id={parseInt(el.id)} xs={12} className="m-0 p-0 g-0">
                                        <p
                                            // style={{fontSize: "0.764rem"
                                            style={{
                                                fontWeight: '500',
                                                fontSize: '9px',
                                                // margin: 10px 0px;
                                                lineHeight: '13.5px',
                                                fontFamily: `'Poppins', sans-serif`,
                                                color: "#ADADAD"
                                            }} className="g-1 m-0 mb-1">
                                            {medicalFieldList.length >0 && medicalFieldList.find(elm => elm.id === parseInt(el.medicalField)).name}
                                        </p>
                                    </Col>
                                    <Row id={parseInt(el.id)}  className='' style={{height: '20px', overflow: "hidden"}}>
                                        <Col id={parseInt(el.id)}  xs={6} className='' style={{height: '20px'}}>
                                            <p  id={parseInt(el.id)} style={{marginBottom: '0px'}}>
                                                <svg id={parseInt(el.id)} width="29" height="14" viewBox="0 0 41 18" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <rect id={parseInt(el.id)} width="41" height="18" rx="2.22642"
                                                          fill="#F3E8E8"/>
                                                    <path id={parseInt(el.id)}
                                                        d="M12.7059 10.7567C12.5656 10.8927 12.5011 11.0893 12.5331 11.2822L13.0146 13.9472C13.0552 14.173 12.9599 14.4016 12.7709 14.5322C12.5856 14.6676 12.3391 14.6838 12.1371 14.5755L9.73806 13.3242C9.65465 13.2798 9.56202 13.256 9.46723 13.2533H9.32044C9.26952 13.2609 9.21969 13.2771 9.17419 13.302L6.7746 14.5592C6.65598 14.6188 6.52165 14.64 6.39002 14.6188C6.06935 14.5582 5.8554 14.2527 5.90794 13.9304L6.39002 11.2654C6.42198 11.0709 6.35752 10.8732 6.21723 10.7351L4.26127 8.83925C4.09769 8.68054 4.04081 8.44221 4.11556 8.22716C4.18815 8.01266 4.3734 7.85612 4.5971 7.82091L7.28919 7.43037C7.49394 7.40925 7.67377 7.28466 7.76585 7.1005L8.9521 4.66841C8.98027 4.61425 9.01656 4.56441 9.06044 4.52216L9.10919 4.48425C9.13465 4.45608 9.1639 4.43279 9.1964 4.41383L9.25544 4.39216L9.34752 4.35425H9.57556C9.77923 4.37537 9.95852 4.49725 10.0522 4.67925L11.2542 7.1005C11.3409 7.27762 11.5093 7.40058 11.7038 7.43037L14.3959 7.82091C14.6234 7.85341 14.8135 8.0105 14.8888 8.22716C14.9597 8.44437 14.8985 8.68271 14.7317 8.83925L12.7059 10.7567Z"
                                                        fill="#C13130"/>
                                                    <path id={parseInt(el.id)}
                                                        d="M20.6562 12.2955V11.2301L24.4276 5.27273H25.267V6.84091H24.7344L22.0369 11.1108V11.179H27.206V12.2955H20.6562ZM24.794 14V11.9716L24.8026 11.4858V5.27273H26.0511V14H24.794ZM30.0376 12.8068L29.9737 13.2713C29.9311 13.6122 29.8587 13.9673 29.7564 14.3366C29.657 14.7088 29.5533 15.054 29.4453 15.3722C29.3402 15.6903 29.2536 15.9432 29.1854 16.1307H28.282C28.3189 15.9545 28.37 15.7159 28.4354 15.4148C28.5007 15.1165 28.5646 14.7827 28.6271 14.4134C28.6896 14.044 28.7365 13.6676 28.7678 13.2841L28.8104 12.8068H30.0376ZM31.8913 14L35.701 6.46591V6.40199H31.2947V5.27273H37.0646V6.44034L33.2678 14H31.8913Z"
                                                        fill="#C13130"/>
                                                </svg>
                                            </p>
                                        </Col>
                                        <Col id={parseInt(el.id)}  xs={6} style={{height: '20px'}}
                                             className='gx-3  d-flex flex-row justify-content-start align-items-end'>
                                            <p id={parseInt(el.id)}  style={{
                                                fontWeight: '500',
                                                fontSize: '8px',
                                                // margin: 10px 0px;
                                                lineHeight: '9.68px',
                                                whiteSpace: 'nowrap',
                                                fontFamily: `'Poppins', sans-serif`,
                                                color: "#ADADAD",
                                                marginBottom: "0px"
                                            }}>
                                                {el.practicYear} il təcrübə
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Card.Text style={{maxHeight: "100px", fontSize: "0.764rem",overflow: "auto"}}>*/}
                        {/*        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sit amet*/}
                        {/*        diam venenatis, tincidunt massa nec, aliquet lacus. Donec non massa*/}
                        {/*        turpis. Nullam enim nisl, ornare sed ornare eu, fermentum sed enim.*/}
                        {/*        Vestibulum ante ipsum primis in faucibus orci luctus et*/}
                        {/*        ultrices posuere cubilia curae; Donec eu sapien at tortor tincidunt*/}
                        {/*        porttitor eget in justo.*/}
                        {/*    </Card.Text>*/}
                        {/*</Row>*/}
                        {/*<Row>*/}
                        {/*    <Col className="d-flex flex-row justify-content-end mt-3 mb-0">*/}
                        {/*        <Button className={`${style.customDangerColor} mb-0`} variant="danger">Həkimə*/}
                        {/*            yazıl</Button>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </Container>
                </Card.Body>
            </Card>
        </Col>)

    let allDoctorsActive = medicalFieldList.length >0 && doctorList.length > 0 && doctorList.map(el =>
        <Col key={el.id} xs={12}
             id={parseInt(el.id)}
             onClick={
                 (el) => {
                     openModalDoctor( el)
                 }}
             style={{
                 transition: 'all  0.2s ease-out'
             }}
             className={`  'd-flex flex-row flex-column nowrap justify-content-start p-1 py-0 g-0 m-0 me-2'
                  ${activeFieldIdList.length!==0 &&
             (!activeFieldIdList.includes(el.medicalField) && 'd-none ')} 
             `}
        >
            <Card key={el.id}
                  id={parseInt(el.id)}
                  onClick={
                      (el) => {
                          openModalDoctor( el)
                      }}
                  className={`shadow-none mb-2`} style={{
                width: 'auto',
                border:' 1px solid #f3e8e8',
                borderRadius: '10px'
            }}>
                <Card.Body id={parseInt(el.id)}  className={`${style.customPaddingImg} mb-0`}>
                    <Container id={parseInt(el.id)}  className="">
                        <Row id={parseInt(el.id)}  className='d-flex flex-row flex-nowrap justify-content-between'>
                            <Col id={parseInt(el.id)}  xs={4}  style={{
                                width: '71px',
                                height: '71px',
                                borderRadius: '50%',
                            }} className={``}>
                                <Image id={parseInt(el.id)} className={`${style.profileCol }`} src={el.image !== null ? el.image : imgProfile}/>
                            </Col>
                            <Col id={parseInt(el.id)}  xs={4}  className=" gx-5" style={{paddingBottom: '0.3rem'}}>
                                <Card.Title id={parseInt(el.id)}  className="m-0">
                                    <p id={parseInt(el.id)}  style={{
                                        fontWeight: '700',
                                        fontSize: '12px',
                                        // margin: 10px 0px;
                                        whiteSpace: 'nowrap',
                                        height: '18px',
                                        lineHeight: '18px',
                                        marginBottom: '0px',
                                        fontFamily: `'Poppins', sans-serif`
                                    }}>Dr. {el.fullname}</p>
                                </Card.Title>
                                <p id={parseInt(el.id)}
                                    // style={{fontSize: "0.764rem"
                                   style={{
                                       fontWeight: '500',
                                       fontSize: '9px',
                                       // margin: 10px 0px;
                                       whiteSpace:'nowrap',
                                       lineHeight: '13.5px',
                                       fontFamily: `'Poppins', sans-serif`,
                                       color: "#ADADAD"
                                   }} className="g-1 m-0  ">
                                    {medicalFieldList.length >0 && medicalFieldList.find(elm => elm.id === parseInt(el.medicalField)).name}
                                </p>
                                <p id={parseInt(el.id)} >
                                    <svg id={parseInt(el.id)} width="41" height="18" viewBox="0 0 41 18" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <rect id={parseInt(el.id)} width="41" height="18" rx="2.22642" fill="#F3E8E8"/>
                                        <path id={parseInt(el.id)}
                                            d="M12.7059 10.7567C12.5656 10.8927 12.5011 11.0893 12.5331 11.2822L13.0146 13.9472C13.0552 14.173 12.9599 14.4016 12.7709 14.5322C12.5856 14.6676 12.3391 14.6838 12.1371 14.5755L9.73806 13.3242C9.65465 13.2798 9.56202 13.256 9.46723 13.2533H9.32044C9.26952 13.2609 9.21969 13.2771 9.17419 13.302L6.7746 14.5592C6.65598 14.6188 6.52165 14.64 6.39002 14.6188C6.06935 14.5582 5.8554 14.2527 5.90794 13.9304L6.39002 11.2654C6.42198 11.0709 6.35752 10.8732 6.21723 10.7351L4.26127 8.83925C4.09769 8.68054 4.04081 8.44221 4.11556 8.22716C4.18815 8.01266 4.3734 7.85612 4.5971 7.82091L7.28919 7.43037C7.49394 7.40925 7.67377 7.28466 7.76585 7.1005L8.9521 4.66841C8.98027 4.61425 9.01656 4.56441 9.06044 4.52216L9.10919 4.48425C9.13465 4.45608 9.1639 4.43279 9.1964 4.41383L9.25544 4.39216L9.34752 4.35425H9.57556C9.77923 4.37537 9.95852 4.49725 10.0522 4.67925L11.2542 7.1005C11.3409 7.27762 11.5093 7.40058 11.7038 7.43037L14.3959 7.82091C14.6234 7.85341 14.8135 8.0105 14.8888 8.22716C14.9597 8.44437 14.8985 8.68271 14.7317 8.83925L12.7059 10.7567Z"
                                            fill="#C13130"/>
                                        <path id={parseInt(el.id)}
                                            d="M20.6562 12.2955V11.2301L24.4276 5.27273H25.267V6.84091H24.7344L22.0369 11.1108V11.179H27.206V12.2955H20.6562ZM24.794 14V11.9716L24.8026 11.4858V5.27273H26.0511V14H24.794ZM30.0376 12.8068L29.9737 13.2713C29.9311 13.6122 29.8587 13.9673 29.7564 14.3366C29.657 14.7088 29.5533 15.054 29.4453 15.3722C29.3402 15.6903 29.2536 15.9432 29.1854 16.1307H28.282C28.3189 15.9545 28.37 15.7159 28.4354 15.4148C28.5007 15.1165 28.5646 14.7827 28.6271 14.4134C28.6896 14.044 28.7365 13.6676 28.7678 13.2841L28.8104 12.8068H30.0376ZM31.8913 14L35.701 6.46591V6.40199H31.2947V5.27273H37.0646V6.44034L33.2678 14H31.8913Z"
                                            fill="#C13130"/>
                                    </svg>
                                </p>
                                <p id={parseInt(el.id)} style={{
                                    fontWeight: '500',
                                    fontSize: '8px',
                                    // margin: 10px 0px;
                                    lineHeight: '9.68px',
                                    whiteSpace: 'nowrap',
                                    fontFamily: `'Poppins', sans-serif`,
                                    color: "#ADADAD",
                                    marginBottom: "0px"
                                }}>
                                    {el.practicYear} il təcrübə
                                </p>
                            </Col>
                            <Col xs={4} id={parseInt(el.id)} className="d-flex flex-row align-items-end justify-content-center mb-2">
                                <Button id={parseInt(el.id)}

                                        style={{
                                            width:'100px',
                                            height:'30px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'nowrap',
                                            alignContent: 'center',
                                            justifyContent: 'center'
                                        }}
                                        className={`${style.btnRed} mb-0 me-2`}
                                        onClick={(e)=>{
                                            shareWpDoctor(e)
                                        }}
                                >
                                    <p
                                        style={{
                                            whiteSpace:'nowrap',
                                            textAlign:'center',
                                            fontSize:'10px',
                                            lineHeight: '15px',
                                            fontFamily: `'Poppins', sans-serif`
                                        }}
                                        className={'g-0 mx-0 px-0'}
                                    >
                                        {navbarByLang.body.doctors.getInformation}
                                    </p>
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>

            </Card>
        </Col>)
    let threeDoctorsForAboutUs = medicalFieldList.length >0 && doctorList.length > 0 && doctorList.slice(0, 3).map(el =>
        <Col key={el.id} xs={4}
             id={parseInt(el.id)}
             onClick={
                 (el) => {
                     openModalDoctor( el)
                 }}
             style={{
                 transition: 'all  0.2s ease-out',
                 marginRight:'0,3125rem'
             }}
             className={`d-flex flex-row flex-column nowrap justify-content-around g-0 `}
        >
            <Card  id={parseInt(el.id)}
                   onClick={
                       (el) => {
                           openModalDoctor( el)
                       }}
                   xs={4} key={el.id} className={`${style.doctorsCards} shadow-none`}
                   style={{
                       width:"auto",
                       minWidth:'80px',
                       minHeight:'80px'
                   }}
            >
                <Card.Body id={parseInt(el.id)}  className={`${style.customPaddingImg} mb-0`}>
                    <Container id={parseInt(el.id)}  className="">
                        <Row id={parseInt(el.id)}  className=''>
                            <Col id={parseInt(el.id)}>
                                <Col id={parseInt(el.id)}  xs={12} className={` d-flex flex-row justify-content-center`}>
                                    <Image id={parseInt(el.id)}  className={`${style.profileCol}`} src={imgProfile}/>
                                </Col>
                                <Col id={parseInt(el.id)}  xs={12} className="">
                                    <Card.Title id={parseInt(el.id)}  className="m-0 p-0 g-0" style={{minHeight: "20px", height:'auto'}}>
                                        <p id={parseInt(el.id)}  style={{
                                            fontWeight: '700',
                                            fontSize: '0.75rem',
                                            // margin: 10px 0px;
                                            whiteSpace: 'nowrap',
                                            height: '18px',
                                            lineHeight: '1.125rem',
                                            marginBottom: '0px',
                                            fontFamily: `'Poppins', sans-serif`
                                        }}>Dr. {spliterFullName(el.fullname)}</p>
                                    </Card.Title>
                                    <Col id={parseInt(el.id)}  xs={12} className="m-0 p-0 g-0">
                                        <p id={parseInt(el.id)}
                                            // style={{fontSize: "0.764rem"
                                           style={{
                                               fontWeight: '500',
                                               fontSize: '9px',
                                               // margin: 10px 0px;
                                               lineHeight: '13.5px',
                                               fontFamily: `'Poppins', sans-serif`,
                                               color: "#ADADAD"
                                           }} className="g-1 m-0 mb-1">
                                            {medicalFieldList.length >0 && medicalFieldList.find(elm => elm.id === parseInt(el.medicalField)).name}
                                        </p>
                                    </Col>

                                </Col>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
        </Col>)
    let fourDoctorsForAboutUs = medicalFieldList.length >0 && doctorList.length > 0 && doctorList.slice(0, 4).map(el =>
        <Col key={el.id}
             id={parseInt(el.id)}
             onClick={
                 (el) => {
                     openModalDoctor( el)
                 }}
             xs={4}
             style={{
                 transition: 'all  0.2s ease-out', cursor:'pointer'
             }}
             className={`d-flex flex-row flex-column nowrap justify-content-start g-0 me-0 ${style.doctorsCardsOut}
                  ${activeFieldIdList.length !==0 && (!activeFieldIdList.includes(el.medicalField) && ' d-none ')}`}
        >
            <Card key={el.id}  id={parseInt(el.id)}
                  onClick={
                      (el) => {
                          openModalDoctor( el)
                      }}
                  className={`${style.doctorsCards} shadow-none`}
            style={
                {minHeight:'146px'}
            }
            >
                <Card.Body id={parseInt(el.id)}  className={`${style.customPaddingImg} mb-0`}>
                    <Container   id={parseInt(el.id)}  className="d-flex flex-row justify-content-start">
                        <Row   id={parseInt(el.id)} style={{width:'114px'}} className=''>
                            <Col id={parseInt(el.id)}  xs={12} className='d-flex flex-column justify-content-center'>
                                <Col id={parseInt(el.id)}  xs={12} className={` d-flex flex-row justify-content-center`}>
                                    <Image id={parseInt(el.id)}  className={`${style.profileCol} justify-self-center`} src={el.image !== null ? el.image : imgProfile} />
                                </Col>
                                <Col id={parseInt(el.id)}  xs={12} className="">
                                    <Card.Title id={parseInt(el.id)}  className="m-0 p-0 g-0" style={{minHeight: "20px", height:'auto'}}>
                                        <p id={parseInt(el.id)}  style={{
                                            fontWeight: '700',
                                            fontSize: '12px',
                                            // margin: 10px 0px;
                                            whiteSpace: 'nowrap',
                                            height: '18px',
                                            lineHeight: '18px',
                                            marginBottom: '0px',
                                            fontFamily: `'Poppins', sans-serif`
                                        }}>Dr. {spliterFullName(el.fullname)}.</p>
                                    </Card.Title>
                                    <Col  id={parseInt(el.id)} xs={12} className="m-0 p-0 g-0">
                                        <p
                                            // style={{fontSize: "0.764rem"
                                            style={{
                                                fontWeight: '500',
                                                fontSize: '9px',
                                                // margin: 10px 0px;
                                                lineHeight: '13.5px',
                                                fontFamily: `'Poppins', sans-serif`,
                                                color: "#ADADAD"
                                            }} className="g-1 m-0 mb-1">
                                            {el.position}
                                            {/*{medicalFieldList.length >0 && medicalFieldList.find(elm => elm.id === parseInt(el.medicalField)).name}*/}
                                        </p>
                                    </Col>
                                    {/*<Row id={parseInt(el.id)}  className='' style={{height: '20px', overflow: "hidden"}}>*/}
                                        {/*<Col className='d-flex flex-row align-items-end' style={{height: '20px'}}>*/}
                                        {/*    <p id={parseInt(el.id)}  style={{*/}
                                        {/*        fontWeight: '500',*/}
                                        {/*        fontSize: '9px',*/}
                                        {/*        // margin: 10px 0px;*/}
                                        {/*        lineHeight: '9.68px',*/}
                                        {/*        whiteSpace: 'nowrap',*/}
                                        {/*        fontFamily: `'Poppins', sans-serif`,*/}
                                        {/*        color: "#ADADAD",*/}
                                        {/*        marginBottom: "0px"*/}
                                        {/*    }}>*/}
                                        {/*        {el.position}*/}
                                        {/*    </p>*/}
                                        {/*</Col>*/}
                                        {/*<Col id={parseInt(el.id)}  xs={6} className='' style={{height: '20px'}}>*/}
                                        {/*    <p  id={parseInt(el.id)} style={{marginBottom: '0px'}}>*/}
                                        {/*        <svg id={parseInt(el.id)} width="29" height="14" viewBox="0 0 41 18" fill="none"*/}
                                        {/*             xmlns="http://www.w3.org/2000/svg">*/}
                                        {/*            <rect id={parseInt(el.id)} width="41" height="18" rx="2.22642"*/}
                                        {/*                  fill="#F3E8E8"/>*/}
                                        {/*            <path id={parseInt(el.id)}*/}
                                        {/*                d="M12.7059 10.7567C12.5656 10.8927 12.5011 11.0893 12.5331 11.2822L13.0146 13.9472C13.0552 14.173 12.9599 14.4016 12.7709 14.5322C12.5856 14.6676 12.3391 14.6838 12.1371 14.5755L9.73806 13.3242C9.65465 13.2798 9.56202 13.256 9.46723 13.2533H9.32044C9.26952 13.2609 9.21969 13.2771 9.17419 13.302L6.7746 14.5592C6.65598 14.6188 6.52165 14.64 6.39002 14.6188C6.06935 14.5582 5.8554 14.2527 5.90794 13.9304L6.39002 11.2654C6.42198 11.0709 6.35752 10.8732 6.21723 10.7351L4.26127 8.83925C4.09769 8.68054 4.04081 8.44221 4.11556 8.22716C4.18815 8.01266 4.3734 7.85612 4.5971 7.82091L7.28919 7.43037C7.49394 7.40925 7.67377 7.28466 7.76585 7.1005L8.9521 4.66841C8.98027 4.61425 9.01656 4.56441 9.06044 4.52216L9.10919 4.48425C9.13465 4.45608 9.1639 4.43279 9.1964 4.41383L9.25544 4.39216L9.34752 4.35425H9.57556C9.77923 4.37537 9.95852 4.49725 10.0522 4.67925L11.2542 7.1005C11.3409 7.27762 11.5093 7.40058 11.7038 7.43037L14.3959 7.82091C14.6234 7.85341 14.8135 8.0105 14.8888 8.22716C14.9597 8.44437 14.8985 8.68271 14.7317 8.83925L12.7059 10.7567Z"*/}
                                        {/*                fill="#C13130"/>*/}
                                        {/*            <path id={parseInt(el.id)}*/}
                                        {/*                d="M20.6562 12.2955V11.2301L24.4276 5.27273H25.267V6.84091H24.7344L22.0369 11.1108V11.179H27.206V12.2955H20.6562ZM24.794 14V11.9716L24.8026 11.4858V5.27273H26.0511V14H24.794ZM30.0376 12.8068L29.9737 13.2713C29.9311 13.6122 29.8587 13.9673 29.7564 14.3366C29.657 14.7088 29.5533 15.054 29.4453 15.3722C29.3402 15.6903 29.2536 15.9432 29.1854 16.1307H28.282C28.3189 15.9545 28.37 15.7159 28.4354 15.4148C28.5007 15.1165 28.5646 14.7827 28.6271 14.4134C28.6896 14.044 28.7365 13.6676 28.7678 13.2841L28.8104 12.8068H30.0376ZM31.8913 14L35.701 6.46591V6.40199H31.2947V5.27273H37.0646V6.44034L33.2678 14H31.8913Z"*/}
                                        {/*                fill="#C13130"/>*/}
                                        {/*        </svg>*/}
                                        {/*    </p>*/}
                                        {/*</Col>*/}
                                        {/*<Col id={parseInt(el.id)}  xs={6} style={{height: '20px'}}*/}
                                        {/*     className='gx-3  d-flex flex-row justify-content-start align-items-end'>*/}
                                        {/*    <p id={parseInt(el.id)}  style={{*/}
                                        {/*        fontWeight: '500',*/}
                                        {/*        fontSize: '8px',*/}
                                        {/*        // margin: 10px 0px;*/}
                                        {/*        lineHeight: '9.68px',*/}
                                        {/*        whiteSpace: 'nowrap',*/}
                                        {/*        fontFamily: `'Poppins', sans-serif`,*/}
                                        {/*        color: "#ADADAD",*/}
                                        {/*        marginBottom: "0px"*/}
                                        {/*    }}>*/}
                                        {/*        {el.practicYear} il təcrübə*/}
                                        {/*    </p>*/}
                                        {/*</Col>*/}
                                    {/*</Row>*/}
                                </Col>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Card.Text style={{maxHeight: "100px", fontSize: "0.764rem",overflow: "auto"}}>*/}
                        {/*        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sit amet*/}
                        {/*        diam venenatis, tincidunt massa nec, aliquet lacus. Donec non massa*/}
                        {/*        turpis. Nullam enim nisl, ornare sed ornare eu, fermentum sed enim.*/}
                        {/*        Vestibulum ante ipsum primis in faucibus orci luctus et*/}
                        {/*        ultrices posuere cubilia curae; Donec eu sapien at tortor tincidunt*/}
                        {/*        porttitor eget in justo.*/}
                        {/*    </Card.Text>*/}
                        {/*</Row>*/}
                        {/*<Row>*/}
                        {/*    <Col className="d-flex flex-row justify-content-end mt-3 mb-0">*/}
                        {/*        <Button className={`${style.customDangerColor} mb-0`} variant="danger">Həkimə*/}
                        {/*            yazıl</Button>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </Container>
                </Card.Body>
            </Card>
        </Col>)
    let loremBig = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."


    const [modalShow, setModalShow] = useState(false);

    const [canShareLink, setIsSharable] = useState(false)
    function canBrowserShareData(data) {
        if (!navigator.share || !navigator.canShare) {
            return false;
        }
        return navigator.canShare(data);
    }


    async function shareData(data) {
            try {
                // debugger
                let resp = await navigator.share(data);
                console.log(resp)
                // The data was shared successfully.
            } catch (e) {
                // The data could not be shared.
                console.error(`Error: ${e}`);
                // alert( ${e})
            }
    }
    useEffect(()=>{
        if (canBrowserShareData(sharedDataSample)) {
            // Enable the share button in the UI.
            setIsSharable(true)
        } else {
            // We can't share on this browser/operating system.
            setIsSharable(false)
        }
    },[])
    // const isNotSelectedStyle = {
    //     padding:'8px 20px',
    //         // backgroundColor: '#F3E8E8',
    //         // color:'#C13130',
    //         fontWeight: '600',
    //     fontSize: '0.75rem',
    //     /* margin: 20px 0px; */
    //     lineHeight: '0.9075rem',
    //     fontFamily: `'Inter', sans-serif`
    // }
    // const isSelectedStyle = {
    //     padding:'8px 20px',
    //     backgroundColor: '#F3E8E8',
    //     color:'#C13130',
    //     fontWeight: '600',
    //     fontSize: '0.75rem',
    //     /* margin: 20px 0px; */
    //     lineHeight: '0.9075rem',
    //     fontFamily: `'Inter', sans-serif`
    // }

const sliderWpText='Sağlıkçının xidmətləri barədə məlumat almaq istəyirəm';
    return (
        <div className={style.wrapper}
             style={ !isPortraitMidSize ? { paddingTop:'20px' } :{}}
              >
            {modalDoctor !== null &&
            <CenteredModal
                shareWpDoctorModal={shareWpDoctorModal}
                doctor={modalDoctor}
                medfield={medicalFieldList}
                navbarByLang={navbarByLang}
                show={modalShow}
                setDoc={setDoc}
                onHide={() => setModalShow(false)}/>
            }
            {showModalSOS !== null &&
            <CenteredModalSOS
                navbarByLang={navbarByLang}
                show={showModalSOS}
                onHide={() => setShowModalSOS(false)}/>
            }
            <div className="whatsupIcon">
                <Image className={`${style.whatsupIcon}`} style={{
                }} src={imgWp}
                   onClick={()=>{
                       window.location.href= 'https://wa.me/'+wpNumber+'?text='+ encodeURIComponent(sliderWpText);
                   }}
                />
            </div>

            {/*  header*/}
            <Container className={`${isPortraitMidSize && style.navbarMobile} px-3`}
                       style={ !isPortraitMidSize ? { height:'50px' } :{}}
                      >
                <Row className={`${style.headerNavbar}
                 px-0 mx-0 d-flex flex-row ${!isPortraitMidSize && 'gx-0'}`}>
                    <Col  className="col-5 px-0 mx-0 g-0"
                         style={isPortraitMinSizeXs === false ? {paddingLeft:'6px'}:{}}>
                        <Image role='button' onClick={reLoadPage} className={`${style.navbarLogo}`} src={appLogo}/>
                    </Col>
                    <Col
                         style={ {paddingRight:'18px'}}
                         className={`${isPortraitMidSize && ''} 
                         d-flex flex-row justify-content-end align-items-center col-7  mx-0 g-0`}>
                        <Col  className=" d-flex flex-row justify-content-end">
                            {/*<Col className=" d-flex flex-row justify-content-start">*/}
                                <MDBDropdown group style={{height:'32px', marginRight:'5px'}}
                                             className={`${style.dropdownLangMobile} shadow-0 g-1 align-items-center rounded-3 `}>
                                    <MDBDropdownToggle className='m-0'
                                                       color='light'>{lang}</MDBDropdownToggle>
                                    <MDBDropdownMenu>
                                        <MDBDropdownItem onClick={(e) => {

                                            changeLanguage(e.target.innerText)
                                        }} id="1" name="AZ" className=""
                                                         link>AZ</MDBDropdownItem>
                                        <MDBDropdownItem onClick={(e) => {
                                            changeLanguage(e.target.innerText)
                                        }} id="2" name="AZ" link>RU</MDBDropdownItem>
                                        <MDBDropdownItem onClick={(e) => {
                                            changeLanguage(e.target.innerText)
                                        }} id="3" name="AZ" link>EN</MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            {/*</Col>*/}

                            <Button onClick={()=>{
                                setShowModalSOS(true)
                            }} className={`${style.buttonSos}
                                                 d-flex flex-row flex-nowrap rounded-3 
                                                 align-items-center justify-content-center`}
                                    style={{}}
                                    variant="light">
                                <MDBIcon style={{fontSize: "14px", marginRight: "5px", width: '14px', height: '14px'}}
                                         fas icon="phone"/>
                                <span style={{lineHeight: '8.66px'}}>SOS</span>
                            </Button>
                        {/*</Col>*/}
                        {/*<Col className="d-flex flex-row justify-content-end">*/}
                            <Image className={style.menuIcon}
                                   onClick={handleShow}
                                   style={ !isPortraitMidSize ? { marginLeft:'10px' } :{}}
                                   src={menuIcon}/>
                        </Col>
                    </Col>
                </Row>
            </Container>

            {/*slider*/}
            <Container fluid
                       className={` d-flex flex-row justify-content-center gx-4`}
                       style={ !isPortraitMidSize ? {paddingTop: '24px',paddingLeft: '20px', paddingRight: '20px'}
                       : {paddingLeft: '20px', paddingRight: '20px',
                       }}

            >
                <Image className="d-flex flex-wrap justify-content-center"
                       onClick={()=>{
                           window.location.href= 'https://wa.me/'+wpNumber+'?text='+ encodeURIComponent(sliderWpText);
                       }}
                       style={{
                           marginLeft: "20px",
                           marginRight: "20px",
                           maxWidth: "100%",
                           minHeight: "135px",
                           height: "parent"
                       }}
                       src={imgSlider}/>

            </Container>
            {/*simptoms*/}
            <Container className={`g-2 ${style.simptoms}`}
                       style={{
                           minWidth: 'auto',
                           // height:"auto"
                       }}
            >
                <Container className={`${style.simptomsHeader}`}>
                    <Row>
                        <Col  className='col-5' >
                            <p className={`${style.headerTextSize} d-flex flex-row align-items-center ms-3`}
                               style={{height:'40px'}}
                            >{navbarByLang.body.simptoms.header}</p>
                        </Col>
                        <Col className='d-flex flex-row align-items-center justify-content-start pe-0 col'>
                            <span className='ms-2' style={{cursor:'pointer'}}>
                                <button
                                    // style={isChild ?isNotSelectedStyle : isSelectedStyle}
                                    style={{height:'32px', padding:'8px 15px', fontSize:"12px"}}
                                        onClick={()=>{
                                            changeIsChild(false)
                                        }}
                                        className={`rounded-3 text-nowrap border-1 shadow-none 
                                            ${style.customButtonSimptom}
                                            ${isChild ?style.btnGrey : style.btnRed }`
                                            }>
                               <span  className={style.fontSimptomButtons}>{navbarByLang.body.simptoms.adult}</span>
                            </button>
                            </span>
                        {/*</Col>*/}
                        {/*<Col className='d-flex flex-row align-items-center  justify-content-start col-3 ps-0 me-0'>*/}
                            <span className='' style={{cursor:'pointer'}}>
                            <button
                                // style={isChild ?isSelectedStyle :isNotSelectedStyle}
                                style={{height:'32px', padding:'8px 15px', fontSize:"12px"}}

                                onClick={()=>{
                                        changeIsChild(true)
                                    }}
                                    className={`border-1 rounded-3 text-nowrap shadow-none 
                                    ${style.customButtonSimptom} 
                                    ${isChild ? style.btnRed :  style.btnGrey}`}>
                               <span  className={style.fontSimptomButtons}>{navbarByLang.body.simptoms.child}</span>
                            </button>
                            </span>
                        </Col>
                    </Row>
                </Container>
                <Container style={{marginLeft:'5px'}} className={`${style.simptomsButtons} `}>
                    {allSimptoms}
                    <Button
                        style={{
                            cursor:"pointer",
                            marginLeft:'5px'
                        }}
                        className={`${style.customButtonSimptom} 
                                    ${activeSimptomsIdList.length > 0 ? style.btnGreySimptom: style.btnRedSimptom}  
                                    rounded-3 text-wrap shadow-none btn-light  `}
                        onClick={()=>{
                            setActiveSimptomsIdList([])
                            setActiveFieldIdList([])
                        }}
                    >
                        <Container className={style.btnSimptom1}>
                            <Row style={{marginTop:'35px'}}>
                   <span className={style.fontSimptomButtons} style={{whiteSpace:'pre-wrap'}}>
                       {navbarByLang.body.simptoms.allSimptoms}
                    </span>
                            </Row>
                            <Row>
                                <Col className='d-flex flex-row justify-content-center align-items-center h-100' style={{
                                    marginLeft:"50px"
                                }}>
                                    {/*<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"*/}
                                    {/*     className="bi bi-arrow-right-circle" viewBox="0 0 16 16">*/}
                                    {/*    <path fillRule="evenodd"*/}
                                    {/*          d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>*/}
                                    {/*</svg>*/}
                                </Col>
                            </Row>
                        </Container >
                    </Button>
                    {/*<button*/}
                    {/*    style={{*/}
                    {/*        cursor:"pointer",*/}
                    {/*        marginLeft:'5px'*/}
                    {/*    }}*/}
                    {/*    className={`${style.customButtonSimptom} */}
                    {/*                ${activeSimptomsIdList.length > 0 ? style.btnGreySimptom: style.btnRedSimptom}  */}
                    {/*                rounded-3 text-wrap shadow-none btn-light  `}*/}
                    {/*    onClick={()=>{*/}
                    {/*        setActiveSimptomsIdList([])*/}
                    {/*        setActiveFieldIdList([])*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*      Bütün simptomlar*/}
                    {/*</button>*/}
                </Container>
            </Container>
            {/*menus*/}
            <Container className={style.mobileMenuIcons}
                       // style={{minWidth: '430px'}}
            >
                <Row className={style.mobileMenuIconsRow}>
                    <Col xs={3} className='d-flex flex-column align-items-center '>
                        <a href='#ourServices' className='d-flex flex-column align-items-center '
                           style={{
                               height: '92px'
                           }}
                        >
                            <Image className="d-flex flex-row flex-wrap align-items-start justify-content-center"
                                   style={{
                                       width: '60px',
                                       height: '52px'
                                   }}
                                   src={imgMenuServicesBig}/>
                            <p className={style.menuIconsText} style={{textAlign:'center'}}>
                                {navbarByLang.body.ourServices.header}
                                {/*Xidmətlər*/}
                            </p>
                        </a>
                    </Col>
                    <Col xs={3} className='d-flex flex-column align-items-center '>
                        <a href='#aboutUs' className='d-flex flex-column align-items-center '
                           style={{
                               height: '92px'
                           }}
                        >
                            <Image className="d-flex flex-wrap justify-content-center"
                                   style={{
                                       width: '60px',
                                       height: '52px'
                                   }}
                                   src={imgMenuAboutUsBig}/>
                            <p className={style.menuIconsText}>
                                {navbarByLang.body.aboutUs.header}

                                {/*Haqqımızda*/}
                            </p>
                        </a>
                    </Col>
                    <Col xs={3} className='d-flex flex-column align-items-center  '>
                        <a href='#contactUs' className='d-flex flex-column align-items-center '
                           style={{
                            height: '92px'
                        }}>
                            <Image className="d-flex flex-wrap justify-content-center"
                                   style={{
                                       width: '60px',
                                       height: '52px'
                                   }}
                                   src={imgMenuContactsBig}/>
                            <p className={style.menuIconsText}>
                                {navbarByLang.body.contacts.header}

                                {/*Əlaqə*/}
                            </p>
                        </a>
                    </Col>
                    <Col xs={3} className={`d-flex flex-column align-items-center `}
                         onClick={async ()=>{
                             shareData(sharedDataSample)
                         }}
                    >
                        <a href='#share' className={`d-flex flex-column align-items-center `}
                           style={canShareLink ? {cursor:'not-allowed'} : {}}
                        >
                            <Image className="d-flex flex-wrap justify-content-center"
                                   style={canShareLink ? {
                                           width: '60px',
                                           height: '52px',
                                           cursor:'not-allowed'
                                       }:
                                       {
                                           width: '60px',
                                           height: '52px',
                                           // backgroundColor:'gray'
                                       }}
                                   alt={canShareLink ? 'Bu cihaz paylanmani desteklemir' : 'Cihaz paylanmani destekleyir'}
                                   src={imgMenuShare3}
                            />
                            <p className={style.menuIconsText} style={{textAlign:'center'}}>
                                {navbarByLang.navbarMenu.shareSaglikci}
                            </p>
                        </a>
                    </Col>
                </Row>
            </Container>

            {/*doctors*/}
            <Container id="doctors"
                       // style={{minWidth: '430px'}}
                       className={`${style.doctors} mb-1 g-2`}>
                <Container className={`${style.doctorsHeader} mx-3  d-flex flex-row`} style={{height:'40px'}}>
                 <Col>
                     <p className={`${style.headerTextSize}`}>
                         {navbarByLang.body.doctors.header}

                         {/*Həkimlərimiz*/}
                         <span className='ms-1' onClick={getAllDocs}>
                            <i className={`fas ${!isAllDoctors ? 'fa-chevron-right' : 'fa-chevron-down'}`}
                               style={{height:'8px',width:'8px'}}>
                            </i>

                        </span>
                     </p>
                 </Col>
                    <Col className='d-flex flex-row align-items-center justify-content-end me-4'>
                        <p style={{
                            fontWeight: '600',
                            fontSize: '10px',
                            color:'#C13130',
                            lineHeight: '15px',
                            marginBottom: '0px',
                            marginRight: '10px',
                            fontFamily: `'Poppins', sans-serif`,
                            cursor:'pointer'
                        }}
                        onClick={getAllDocs}
                        >
                            {isAllDoctors ?
                                <svg style={
                                    {
                                        color:'#C13130',
                                    }}
                                  width="18" height="18" viewBox="0 0 14 14"   xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#C13130" d="M2 3.5H12C13.1046 3.5 14 4.39543 14 5.5V8.5C14 9.60457 13.1046 10.5 12 10.5H2C0.89543 10.5 0 9.60457 0 8.5V5.5C0 4.39543 0.895431 3.5 2 3.5ZM2 4.5C1.44772 4.5 1 4.94772 1 5.5V8.5C1 9.05228 1.44772 9.5 2 9.5H12C12.5523 9.5 13 9.05228 13 8.5V5.5C13 4.94772 12.5523 4.5 12 4.5H2Z"  />
                                    <path fill="#C13130" d="M0 1C0 0.723858 0.223858 0.5 0.5 0.5H13.5C13.7761 0.5 14 0.723858 14 1C14 1.27614 13.7761 1.5 13.5 1.5H0.5C0.223858 1.5 0 1.27614 0 1Z"  />
                                    <path fill="#C13130" d="M0 13C0 12.7239 0.223858 12.5 0.5 12.5H13.5C13.7761 12.5 14 12.7239 14 13C14 13.2761 13.7761 13.5 13.5 13.5H0.5C0.223858 13.5 0 13.2761 0 13Z"  />
                                </svg>
                                : `${navbarByLang.body.doctors.allDoctors}`
                            }
                        </p>
                    </Col>
                </Container>
                <Container className={` ${style.doctorsButtonsContainer}`}>
                   <Container
                       className={` ${style.doctorsButtons} d-flex flex-row align-items-start`}
                      >
                       {allMedicalActiveField}
                       {allMedicalDeactiveField}
                   </Container>
                </Container>
                <Container
                    //min3col+12px paddingBottom ${doctorList.length < 2 && 'pb-2'}
                    className={`g-2 pt-1 px-3 m-0 d-flex flex-row 
                    ${!isAllDoctors ? 'flex-nowrap overflow-auto' : 'flex-wrap '} 
                    justify-content-start align-content-start `}>
                    {
                        !isAllDoctors
                            ?
                         allDoctorsNotActive
                            :
                         allDoctorsActive
                    }
                </Container>
            </Container>
            {/*ourServices*/}
            <div className=''>
                <Container id='ourServices'
                           className={` ${style.ourServices} p-0 mx-3`}
                           style={{background: "#F7F8F9", minWidth: '240px'}}>
                    <Row className="g-0 m-0 ps-4">
                        <p className={`${style.headerTextSize} ${style.ourServicesHeader} g-0 ms-0`}
                           style={{margin: '10px 0px 5px 0px'}}
                        >
                            {navbarByLang.body.ourServices.header}
                            {/*Xidmətlərimiz*/}
                        </p>
                    </Row>
                    <Row className={`${style.ourServicesAccordions}`}>
                        <MDBAccordion
                            style={{paddingLeft: "5px", minHeight:'146px'}}
                            // onChange={changeChevronsTo90Degree}
                            borderless="true"
                            initialActive={0}>
                            <MDBAccordionItem
                                collapseId={1}
                                onClick={() => {
                                    changeChevronPosition(1)
                                }}
                                headerTitle={
                                    <>
                                        {/*{color:'#C13130',transition: 'all  0.5s ease-out'}*/}
                                        <MDBIcon
                                            className={`${style.chevron} ${!isChevronRight1 ? style.chevronDownTransact : ''}`}
                                            // rotate={isChevronRight1 && '90'}
                                            // style={isChevronRight1
                                            //     ? transitionForChevron
                                            //     : {...transitionForChevron, ...{transform: 'rotate(90deg)'}}}
                                            fas icon="chevron-right"/> &nbsp;
                                        {navbarByLang.body.ourServices.data.ourServicesHead1}
                                    </>
                                }>
                                {navbarByLang.body.ourServices.data.ourServicesHead1Text}
                                {/*{ourServicesHead1Text}*/}
                            </MDBAccordionItem>
                            <MDBAccordionItem
                                collapseId={2}
                                className="border-0"
                                onClick={() => {
                                    changeChevronPosition(2)
                                }}
                                headerTitle={
                                    <>
                                        <MDBIcon
                                            className={`${style.chevron} ${!isChevronRight2 ? style.chevronDownTransact : ''}`}
                                            fas icon="chevron-right"/> &nbsp; {navbarByLang.body.ourServices.data.ourServicesHead2}
                                    </>
                                }>
                                {navbarByLang.body.ourServices.data.ourServicesHead2Text}
                                {/*{ourServicesHead2Text}*/}
                            </MDBAccordionItem>
                            <MDBAccordionItem
                                collapseId={5}
                                className="border-0"
                                onClick={
                                    () => {
                                        changeChevronPosition(5)

                                    }}
                                headerTitle={
                                    <div className='d-flex flex-row align-items-center'>
                                        <MDBIcon
                                            className={`${style.chevron} ${!isChevronRight5 ? style.chevronDownTransact : ''} 
                                            `}
                                            fas icon="chevron-right"/>
                                        &nbsp; <span style={{whiteSpace:"nowrap"}}> {navbarByLang.body.ourServices.data.ourServicesHead5}</span>
                                    </div>
                                }>
                                {navbarByLang.body.ourServices.data.ourServicesHead5Text}
                                {/*{loremBig.substring(0, 40)}*/}
                                {/*<p>*/}
                                {/*    <Image className={style.downloadFileBtn}*/}
                                {/*           style={{}}*/}
                                {/*           src={imgDownloadFileBtn}/>*/}
                                {/*</p>*/}
                            </MDBAccordionItem>
                        </MDBAccordion>

                    </Row>
                </Container>
            </div>
            {/*ourPaertners*/}
            <div style={{margin: "20px 0px", minWidth: 'auto'}}>
                <Container className={`${style.partners}  mx-3`}>
                    <div className={` `}>
                        {/*${style.partnersHeader}*/}
                        <p className={`${style.headerTextSize}`}>
                            {navbarByLang.body.partners.header}
                        </p>
                    </div>
                    <Container className={`${style.partnersLogos}  g-0`}>
                        <Row className="
                            d-flex flex-row flex-nowrap
                            justify-content-start
                            overflow-auto
                            column-gap-2
                            g-2"
                             style={{
                                 backgroundColor: "#F7F8F9",
                                 // width: "auto",
                                 // minHeight: "104px",
                             }}>
                            {/*<Col>*/}
                            <Image className={`${style.iconPartners}`}
                                   style={{}}
                                   src={imgMedicSchool}/>
                            {/*</Col>*/}
                            {/*<Col>*/}
                            {/*<Image className={`${style.iconPartners}`}*/}
                            {/*       style={{}}*/}
                            {/*       src={imgITS}/>*/}
                            {/*</Col>*/}
                            {/*<Col>*/}
                            {/*<Image className={`${style.iconPartners}`}*/}
                            {/*       style={{}}*/}
                            {/*       src={imgAAC}/>*/}
                            {/*</Col>*/}
                            {/*<Col>*/}
                            {/*<Image className={`${style.iconPartners}`}*/}
                            {/*       style={{}}*/}
                            {/*       src={imgMega}/>*/}
                            {/*</Col>*/}
                            {/*<Col>*/}
                            {/*<Image className={`${style.iconPartners}`}*/}
                            {/*       style={{}}*/}
                            {/*       src={imgAtewgah}/>*/}
                            {/*</Col>*/}
                            {/*<Col>*/}
                            {/*<Image className={`${style.iconPartners}`}*/}
                            {/*       style={{}}*/}
                            {/*       src={imgPasha}/>*/}
                            {/*</Col>*/}
                        </Row>
                    </Container>
                </Container>
            </div>
            {/*aboutUs*/}
            <Container id="aboutUs"
                       style={{minWidth: 'auto'}}
                       className={`d-flex flex-column justify-content-start ${style.aboutUs} px-3`}>
                <Container style={{
                    border: ' 1px solid #F3E8E8',
                    borderRadius: '15px',
                    // minHeight:'480px'

                }}>
                    <Container fluid='true'>
                        <Container className={`${style.aboutUsHeader}`}>
                            <p className={`${style.headerTextSize} py-0`}
                               style={{}}
                            >{navbarByLang.body.aboutUs.header}</p>
                        </Container>
                        <Container className={` g-0`}>
                            <p className={`${style.aboutUsDescription} mx-3`}
                               style={{}}
                            >
                                {navbarByLang.body.aboutUs.description}
                                {/*{aboutUsText}*/}
                            </p>
                        </Container>
                    </Container>
                    {/*<Container style={{}}*/}
                    {/*           className={`d-flex flex-row flex-nowrap justify-content-between overflow-x-scroll g-0 column-gap-1 `}>*/}
                    {/*    {fourDoctorsForAboutUs}*/}
                    {/*</Container>*/}
                </Container>

            </Container>

            {/*contactUs*/}
            <Container id="contactUs"
                       style={{minWidth: 'auto', marginTop:'10px'}}
                       className={`${style.contactUs} px-3`}>
                <Container style={{
                    border: ' 1px solid #F3E8E8',
                    borderRadius: '15px',
                }}>
                    <Row className='px-2 d-flex flex-row justify-content-between '>
                        <Col xs={6} className='d-flex flex-column justify-content-start align-items-start'>
                            <Row className={`${style.contactUsHeader}`}>
                                <p className={`${style.headerTextSize}`} style={{marginBottom: "20px"}}
                                >
                                    {navbarByLang.body.contacts.header}
                                </p>
                            </Row>

                            <Col xs={6} className={`${style.contactUsRowCol1_Col1}`}>
                                <div className="contactUsSosials">
                                    <Image style={{width: "110px", height: "30px", marginBottom: '10px'}}
                                           src={imgSosialsALL}/>
                                </div>
                                <div className={`${style.contactUsContacts}`}>
                                    <p className="g-0 m-0">
                                        <MDBIcon style={{color: 'black', marginRight: "5px"}}
                                                 fas icon="phone"/>
                                        <span className={`${style.contactUsInfoSize1}`}>
                                +994 70 266 65 10
                                </span>
                                    </p>
                                    <p className="g-0 m-0">
                                        <MDBIcon style={{color: 'black', marginRight: "5px"}}
                                                 far icon="envelope"/>
                                        <span className={`${style.contactUsInfoSize1}`}>
                                info@saglikci.az
                                </span>
                                    </p>
                                    <p className="g-0 m-0">
                                        <MDBIcon style={{color: 'black', marginRight: "5px"}}
                                                 fas icon="map-marker-alt"/>
                                        <span className={`${style.contactUsInfoSize1}`}>
                                Baku, Azərbaycan
                                </span>
                                    </p>
                                </div>
                            </Col>
                        </Col>
                        <Col xs={6} className='p-3'>
                            <Row className={`${style.contactUsRow}`}>
                                <Col xs={12} className=" mt-3 ">
                                    <Col className={`${style.contactUsCol2Downloads}`}>
                                        <Image src={isPortraitMidSize ? imgDownloadAppMobile : imgPlayStore}
                                               style={
                                                        isPortraitMidSize
                                                       ?
                                                       {
                                                           width: "81px",
                                                           height: "51px",
                                                           cursor: "pointer"
                                                       }
                                                       :
                                                       {
                                                           width: "410px",
                                                           height: "130px",
                                                           marginBottom: "5px",
                                                           cursor: "pointer"
                                                       }
                                               }/>
                                    </Col>
                                </Col>
                                <Col xs={12} sm={6} md={6} xl={4}
                                     className={`${style.contactUsCol} mt-2`}>
                                    <div
                                        // className={`${style.contactUsHeader}`}
                                        className={`${style.headerTextSize} d-flex flex-column justify-content-start m-0`}
                                    >
                                        <p style={{
                                            fontWeight: '700',
                                            fontSize: `12px`,
                                            lineHeight: ` 18px`,
                                            fontFamily: `'Poppins', sans-serif`,
                                        }}
                                        >
                                            {navbarByLang.body.contacts.workTimeHeader}
                                        </p>
                                    </div>
                                    <div className={`${style.contactUsInfos}`}>
                                        <p className={`${style.contactUsInfoSize2} m-0 p-0`}>{navbarByLang.body.contacts.workTime}</p>
                                        <p className={`${style.contactUsInfoSize2} m-0 p-0`}>{navbarByLang.body.contacts.description}</p>
                                        <p className={`${style.contactUsInfoSize2} m-0 p-0`}>{navbarByLang.body.contacts.description2nd}</p>
                                    </div>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Row className='d-flex  mt-2'>
                    <p style={{
                        fontWeight: '500',
                        fontSize: `12px`,
                        lineHeight: ` 18px`,
                        fontFamily: `'Poppins', sans-serif`,
                        color: 'black',
                        height: '18px',
                        textAlign: 'center '
                    }}>© Sağlıkçı 2024</p>
                </Row>
            </Container>
            <Offcanvas
                placement='end'
                show={show}
                className={style.slideMenuCanvas}
                style={{

                    // filter:"blur(1px)"
                }}
                onHide={handleClose}>
                <Offcanvas.Header className="me-2  ms-3" style={{paddingTop:'10px'}}>
                    {/*<Offcanvas.Title>Title</Offcanvas.Title>*/}
                    <Container fluid className="g-0">
                        <Row className="w-auto">
                            <Col
                                className="d-flex flex-row align-items-center justify-content-start"
                                xs={6}>
                                <Col xs={3} className=" d-flex flex-row justify-content-start">
                                    <Image className={style.closeMobileMenuButton}
                                           onClick={handleClose}
                                           style={{}}
                                           src={imgLogoMini}/>
                                </Col>
                                <Col className=" d-flex flex-row justify-content-start">
                                    <MDBDropdown group style={{}}
                                                 className={`${style.dropdownLangMobile} shadow-0 g-1   `}>
                                        <MDBDropdownToggle className='m-0'
                                                           color='light'>{lang}</MDBDropdownToggle>
                                        <MDBDropdownMenu>
                                            <MDBDropdownItem onClick={(e) => {
                                                changeLanguage(e.target.innerText)
                                            }} id="1" name="AZ" className=""
                                                             link>AZ</MDBDropdownItem>
                                            <MDBDropdownItem onClick={(e) => {
                                                changeLanguage(e.target.innerText)
                                            }} id="2" name="AZ" link>RU</MDBDropdownItem>
                                            <MDBDropdownItem onClick={(e) => {
                                                changeLanguage(e.target.innerText)
                                            }} id="3" name="AZ" link>EN</MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </Col>
                            </Col>
                            <Col
                                className="d-flex flex-row align-items-center justify-content-end gx-0  "
                                xs={6}>
                                <Col className=" d-flex flex-row justify-content-end"
                                >
                                    <Button
                                        onClick={()=>{
                                            setShowModalSOS(true)
                                        }}
                                        className={`${style.buttonSos} d-flex flex-row flex-nowrap rounded-3 align-items-center justify-content-center`}
                                        style={{}}
                                        variant="light">
                                        <MDBIcon style={{
                                            fontSize: "14px",
                                            marginRight: "5px",
                                            width: '14px',
                                            height: '14px'
                                        }}
                                                 fas icon="phone"/>
                                        <span style={{lineHeight: '8.66px'}}>SOS</span>
                                    </Button>
                                </Col>
                                <Col className="ms-3 me-1 d-flex flex-row justify-content-end"
                                     xs={1}>
                                    <Image className={`${style.closeMobileMenuButton}`}
                                           onClick={handleClose}
                                           style={{}}
                                           src={imgCloseButton}/>
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Container fluid
                               className=" g-0 d-flex flex-column row-gap-3 align-items-end justify-content-end me-2 pe-0">
                        <Col xs={12} className="d-flex flex-row  ">
                            <Col className="d-flex flex-row justify-content-end
                                        align-items-center
                                        ">
                                <a href={"#doctors"}
                                   className={`text-black ${style.mobileMenuTextSize}
                                                text-capitalize
                                                rounded-6`}
                                >
                                    {navbarByLang.navbarMenu.doctors}
                                </a>
                            </Col>
                            <Col xs={1} className="ms-3 d-flex flex-row justify-content-end me-0  ">
                                <Image className={`${style.closeMobileMenuButton}`}
                                       style={{}}
                                       src={imgMenuDoctors}/>
                            </Col>
                        </Col>
                        <Col xs={12} className="d-flex
                                    flex-row justify-content-center">

                            <Col className="d-flex flex-row justify-content-end align-items-center">
                                <a href={"#ourServices"}
                                   className={`text-capitalize  ${style.mobileMenuTextSize}
                                               text-black  rounded-6`}
                                >
                                    {navbarByLang.navbarMenu.ourServices}
                                </a>
                            </Col>
                            <Col xs={1} className="ms-3 d-flex flex-row justify-content-end me-0">
                                <Image className={`${style.closeMobileMenuButton}`}
                                       style={{}}
                                       src={imgMenuServices}/>
                            </Col>
                        </Col>
                        <Col xs={12} className="d-flex
                                    flex-row justify-content-center">

                            <Col className="d-flex flex-row justify-content-end align-items-center">
                                <a href={"#aboutUs"}
                                   className={`text-capitalize  ${style.mobileMenuTextSize}
                                               text-black  rounded-6`}
                                >
                                    {navbarByLang.navbarMenu.abouUs}
                                </a>
                            </Col>
                            <Col xs={1} className=" ms-3 d-flex flex-row justify-content-end ">
                                <Image className={`${style.closeMobileMenuButton}`}
                                       style={{}}
                                       src={imgMenuAboutUs}/>
                            </Col>
                        </Col>
                        <Col xs={12} className="d-flex
                                    flex-row justify-content-center">
                            <Col className="d-flex flex-row justify-content-end align-items-center">
                                <a href={"#contactUs"}
                                   className={`text-capitalize ${style.mobileMenuTextSize}
                                               text-black rounded-6`}
                                >
                                    {navbarByLang.navbarMenu.contact}
                                </a>
                            </Col>
                            <Col xs={1} className="ms-3 d-flex flex-row justify-content-end ">
                                <Image className={`${style.closeMobileMenuButton}`}
                                       style={{}}
                                       src={imgMenuContacts}/>
                            </Col>
                        </Col>
                        <Col xs={12} className="d-flex
                                    flex-row justify-content-end">
                            <Col className="d-flex flex-row justify-content-end">
                                <a href={"#"}
                                   className={`${style.mobileMenuTextSize} w-auto
                                               text-capitalize text-black rounded-6 `}

                                >
                               <span style={{color: '#C13130'}}>
                                   {navbarByLang.navbarMenu.shareSaglikci}
                               </span>
                                </a>
                            </Col>
                            <Col xs={1}
                                 onClick={async ()=>{
                                     shareData(sharedDataSample)
                                 }}
                                 className="ms-3  d-flex flex-row justify-content-end align-items-center">
                                <Image className={`${style.closeMobileMenuButton}`}
                                       style={{}}
                                       src={imgMenuShare}/>
                            </Col>
                        </Col>
                    </Container>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )

}
export default Mobile;

{/*{*/
}
{/*    !is670px &&*/
}
{/*    <Container fluid className="g-1 pt-0 mt-0 mx-0 px-0 mb-3">*/
}
{/*        <Col xs={12} className={`${isDesktopOrLaptop && 'navbarSearch'}`}*/
}
{/*             style={isTabletOrMobile ? {minwidth: "200px", width: "auto", height: "50px"} : {}}>*/
}
{/*            <InputGroup style={{minwidth: "300px", height: "50px", marginRight: '10px'} }*/
}
{/*                        className={`${isDesktopOrLaptop && 'ml-3'} shadow-0`}>*/
}
{/*                <InputGroup.Text style={{*/
}
{/*                    borderLeft: '1px solid #CDCDCD',*/
}
{/*                    borderTop: '1px solid #CDCDCD',*/
}
{/*                    borderRight: '0px solid #CDCDCD',*/
}
{/*                    borderBottom: '1px solid #CDCDCD',*/
}
{/*                    height: "50px",*/
}
{/*                    borderRadius: '10px 0px 0px 10px'*/
}
{/*                }}*/
}
{/*                                 id="basic-addon1">*/
}
{/*                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"*/
}
{/*                         xmlns="http://www.w3.org/2000/svg">*/
}
{/*                        <path fillRule="evenodd" clipRule="evenodd"*/
}
{/*                              d="M9.78565 2.20691e-08C8.2251 0.000132966 6.68721 0.373443 5.30028 1.08878C3.91336 1.80413 2.71761 2.84076 1.81282 4.11219C0.90802 5.38362 0.320406 6.85298 0.0989992 8.39769C-0.122407 9.94239 0.028814 11.5176 0.540047 12.992C1.05128 14.4664 1.9077 15.7972 3.03786 16.8732C4.16801 17.9493 5.53913 18.7396 7.03682 19.178C8.5345 19.6165 10.1153 19.6904 11.6474 19.3937C13.1795 19.0969 14.6184 18.4381 15.8441 17.4722L20.0488 21.6768C20.2659 21.8865 20.5568 22.0026 20.8587 22C21.1605 21.9973 21.4493 21.8763 21.6628 21.6628C21.8762 21.4493 21.9973 21.1606 22 20.8587C22.0026 20.5568 21.8865 20.266 21.6768 20.0489L17.4721 15.8443C18.6096 14.4013 19.3179 12.6671 19.5158 10.8404C19.7138 9.01364 19.3934 7.16806 18.5914 5.51487C17.7894 3.86168 16.5382 2.46766 14.9809 1.49236C13.4236 0.51706 11.6232 -0.000123355 9.78565 2.20691e-08ZM2.30187 9.78611C2.30187 7.80137 3.09034 5.89791 4.49382 4.49448C5.8973 3.09105 7.80083 2.30261 9.78565 2.30261C11.7705 2.30261 13.674 3.09105 15.0775 4.49448C16.481 5.89791 17.2694 7.80137 17.2694 9.78611C17.2694 11.7709 16.481 13.6743 15.0775 15.0777C13.674 16.4812 11.7705 17.2696 9.78565 17.2696C7.80083 17.2696 5.8973 16.4812 4.49382 15.0777C3.09034 13.6743 2.30187 11.7709 2.30187 9.78611Z"*/
}
{/*                              fill="#CDCDCD"/>*/
}
{/*                    </svg>*/
}
{/*                </InputGroup.Text>*/
}
{/*                <Form.Control style={{*/
}
{/*                    borderRight: '1px solid #CDCDCD',*/
}
{/*                    borderLeft: '0px solid #CDCDCD',*/
}
{/*                    borderTop: '1px solid #CDCDCD',*/
}
{/*                    borderBottom: '1px solid #CDCDCD',*/
}
{/*                    height: "50px",*/
}
{/*                    borderRadius: '0px 10px 10px 0px'*/
}
{/*                }}*/
}
{/*                              placeholder={navbarByLang.header.searchPlaceholder}*/
}
{/*                              aria-label="search"*/
}
{/*                              aria-describedby="basic-addon1"*/
}
{/*                />*/
}
{/*            </InputGroup>*/
}
{/*        </Col>*/
}
{/*    </Container>*/
}
{/*}*/
}

//
// <Col xs={12} md={6} xl={4} className="d-flex flex-row justify-content-center p-1 g-0 m-0 mb-2">
//     <Card className={`${style.doctorsCards} shadow-none `}>
//         <Card.Body className={`${style.customPaddingImg} mb-0`}>
//             <Container className="">
//                 <Row>
//                     <Col xs={4} className={`${style.profileCol}  `}>
//                         <Image className={`${style.profileCol}`} src={imgProfile}/>
//                     </Col>
//                     <Col xs={8} className=" gx-5">
//                         <Card.Title className="m-0">
//                             Dr. Ad Soyad
//                         </Card.Title>
//                         <p style={{fontSize: "0.764rem"}} className="g-1 m-0">
//                             Kardiolog
//                         </p>
//                         <p>
//                             <svg width="41" height="18" viewBox="0 0 41 18" fill="none"
//                                  xmlns="http://www.w3.org/2000/svg">
//                                 <rect width="41" height="18" rx="2.22642" fill="#F3E8E8"/>
//                                 <path
//                                     d="M12.7059 10.7567C12.5656 10.8927 12.5011 11.0893 12.5331 11.2822L13.0146 13.9472C13.0552 14.173 12.9599 14.4016 12.7709 14.5322C12.5856 14.6676 12.3391 14.6838 12.1371 14.5755L9.73806 13.3242C9.65465 13.2798 9.56202 13.256 9.46723 13.2533H9.32044C9.26952 13.2609 9.21969 13.2771 9.17419 13.302L6.7746 14.5592C6.65598 14.6188 6.52165 14.64 6.39002 14.6188C6.06935 14.5582 5.8554 14.2527 5.90794 13.9304L6.39002 11.2654C6.42198 11.0709 6.35752 10.8732 6.21723 10.7351L4.26127 8.83925C4.09769 8.68054 4.04081 8.44221 4.11556 8.22716C4.18815 8.01266 4.3734 7.85612 4.5971 7.82091L7.28919 7.43037C7.49394 7.40925 7.67377 7.28466 7.76585 7.1005L8.9521 4.66841C8.98027 4.61425 9.01656 4.56441 9.06044 4.52216L9.10919 4.48425C9.13465 4.45608 9.1639 4.43279 9.1964 4.41383L9.25544 4.39216L9.34752 4.35425H9.57556C9.77923 4.37537 9.95852 4.49725 10.0522 4.67925L11.2542 7.1005C11.3409 7.27762 11.5093 7.40058 11.7038 7.43037L14.3959 7.82091C14.6234 7.85341 14.8135 8.0105 14.8888 8.22716C14.9597 8.44437 14.8985 8.68271 14.7317 8.83925L12.7059 10.7567Z"
//                                     fill="#C13130"/>
//                                 <path
//                                     d="M20.6562 12.2955V11.2301L24.4276 5.27273H25.267V6.84091H24.7344L22.0369 11.1108V11.179H27.206V12.2955H20.6562ZM24.794 14V11.9716L24.8026 11.4858V5.27273H26.0511V14H24.794ZM30.0376 12.8068L29.9737 13.2713C29.9311 13.6122 29.8587 13.9673 29.7564 14.3366C29.657 14.7088 29.5533 15.054 29.4453 15.3722C29.3402 15.6903 29.2536 15.9432 29.1854 16.1307H28.282C28.3189 15.9545 28.37 15.7159 28.4354 15.4148C28.5007 15.1165 28.5646 14.7827 28.6271 14.4134C28.6896 14.044 28.7365 13.6676 28.7678 13.2841L28.8104 12.8068H30.0376ZM31.8913 14L35.701 6.46591V6.40199H31.2947V5.27273H37.0646V6.44034L33.2678 14H31.8913Z"
//                                     fill="#C13130"/>
//                             </svg>
//                         </p>
//                         <p style={{fontSize: "0.764rem"}}>
//                             10 il təcrübə
//                         </p>
//                     </Col>
//                 </Row>
//                 <Row>
//                     <Card.Text style={{maxHeight: "100px", fontSize: "0.764rem",overflow: "auto"}}>
//                         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sit amet
//                         diam venenatis, tincidunt massa nec, aliquet lacus. Donec non massa
//                         turpis. Nullam enim nisl, ornare sed ornare eu, fermentum sed enim.
//                         Vestibulum ante ipsum primis in faucibus orci luctus et
//                         ultrices posuere cubilia curae; Donec eu sapien at tortor tincidunt
//                         porttitor eget in justo.
//                     </Card.Text>
//                 </Row>
//                 <Row>
//                     <Col className="d-flex flex-row justify-content-end mt-3 mb-0">
//                         <Button className={`${style.customDangerColor} mb-0`} variant="danger">Həkimə
//                             yazıl</Button>
//                     </Col>
//                 </Row>
//             </Container>
//         </Card.Body>
//     </Card>
// </Col>


//doctors
{/*<Col xs={12} md={6} xl={4} className="d-flex flex-row nowrap justify-content-center p-1 g-0 m-0 mb-2">*/
}
{/*    <Card className={`${style.doctorsCards} shadow-none `}>*/
}
{/*        <Card.Body className={`${style.customPaddingImg} mb-0`}>*/
}
{/*            <Container className="">*/
}
{/*                <Row>*/
}
{/*                    <Col xs={4} className={`${style.profileCol}  `}>*/
}
{/*                        <Image className={`${style.profileCol}`} src={imgProfile}/>*/
}
{/*                    </Col>*/
}
{/*                    <Col xs={8} className=" gx-5">*/
}
{/*                        <Card.Title className="m-0">*/
}
{/*                            Dr. Ad Soyad*/
}
{/*                        </Card.Title>*/
}
{/*                        <p style={{fontSize: "0.764rem"}} className="g-1 m-0">*/
}
{/*                            Kardiolog*/
}
{/*                        </p>*/
}
{/*                        <p>*/
}
{/*                            <svg width="41" height="18" viewBox="0 0 41 18" fill="none"*/
}
{/*                                 xmlns="http://www.w3.org/2000/svg">*/
}
{/*                                <rect width="41" height="18" rx="2.22642" fill="#F3E8E8"/>*/
}
{/*                                <path*/
}
{/*                                    d="M12.7059 10.7567C12.5656 10.8927 12.5011 11.0893 12.5331 11.2822L13.0146 13.9472C13.0552 14.173 12.9599 14.4016 12.7709 14.5322C12.5856 14.6676 12.3391 14.6838 12.1371 14.5755L9.73806 13.3242C9.65465 13.2798 9.56202 13.256 9.46723 13.2533H9.32044C9.26952 13.2609 9.21969 13.2771 9.17419 13.302L6.7746 14.5592C6.65598 14.6188 6.52165 14.64 6.39002 14.6188C6.06935 14.5582 5.8554 14.2527 5.90794 13.9304L6.39002 11.2654C6.42198 11.0709 6.35752 10.8732 6.21723 10.7351L4.26127 8.83925C4.09769 8.68054 4.04081 8.44221 4.11556 8.22716C4.18815 8.01266 4.3734 7.85612 4.5971 7.82091L7.28919 7.43037C7.49394 7.40925 7.67377 7.28466 7.76585 7.1005L8.9521 4.66841C8.98027 4.61425 9.01656 4.56441 9.06044 4.52216L9.10919 4.48425C9.13465 4.45608 9.1639 4.43279 9.1964 4.41383L9.25544 4.39216L9.34752 4.35425H9.57556C9.77923 4.37537 9.95852 4.49725 10.0522 4.67925L11.2542 7.1005C11.3409 7.27762 11.5093 7.40058 11.7038 7.43037L14.3959 7.82091C14.6234 7.85341 14.8135 8.0105 14.8888 8.22716C14.9597 8.44437 14.8985 8.68271 14.7317 8.83925L12.7059 10.7567Z"*/
}
{/*                                    fill="#C13130"/>*/
}
{/*                                <path*/
}
{/*                                    d="M20.6562 12.2955V11.2301L24.4276 5.27273H25.267V6.84091H24.7344L22.0369 11.1108V11.179H27.206V12.2955H20.6562ZM24.794 14V11.9716L24.8026 11.4858V5.27273H26.0511V14H24.794ZM30.0376 12.8068L29.9737 13.2713C29.9311 13.6122 29.8587 13.9673 29.7564 14.3366C29.657 14.7088 29.5533 15.054 29.4453 15.3722C29.3402 15.6903 29.2536 15.9432 29.1854 16.1307H28.282C28.3189 15.9545 28.37 15.7159 28.4354 15.4148C28.5007 15.1165 28.5646 14.7827 28.6271 14.4134C28.6896 14.044 28.7365 13.6676 28.7678 13.2841L28.8104 12.8068H30.0376ZM31.8913 14L35.701 6.46591V6.40199H31.2947V5.27273H37.0646V6.44034L33.2678 14H31.8913Z"*/
}
{/*                                    fill="#C13130"/>*/
}
{/*                            </svg>*/
}
{/*                        </p>*/
}
{/*                        <p style={{fontSize: "0.764rem"}}>*/
}
{/*                            10 il təcrübə*/
}
{/*                        </p>*/
}
{/*                    </Col>*/
}
{/*                </Row>*/
}
{/*                <Row>*/
}
{/*                    <Card.Text style={{maxHeight: "100px", fontSize: "0.764rem",overflow: "auto"}}>*/
}
{/*                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sit amet*/
}
{/*                        diam venenatis, tincidunt massa nec, aliquet lacus. Donec non massa*/
}
{/*                        turpis. Nullam enim nisl, ornare sed ornare eu, fermentum sed enim.*/
}
{/*                        Vestibulum ante ipsum primis in faucibus orci luctus et*/
}
{/*                        ultrices posuere cubilia curae; Donec eu sapien at tortor tincidunt*/
}
{/*                        porttitor eget in justo.*/
}
{/*                    </Card.Text>*/
}
{/*                </Row>*/
}
{/*                <Row>*/
}
{/*                    <Col className="d-flex flex-row justify-content-end mt-3 mb-0">*/
}
{/*                        <Button className={`${style.customDangerColor} mb-0`} variant="danger">Həkimə*/
}
{/*                            yazıl</Button>*/
}
{/*                    </Col>*/
}
{/*                </Row>*/
}
{/*            </Container>*/
}
{/*        </Card.Body>*/
}
{/*    </Card>*/
}
{/*</Col>*/}