import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import reportWebVitals from './reportWebVitals';
import MobileView from "./MobileView";
// import * as serviceWorker from './service-worker';
// /* Core CSS required for Ionic components to work propaerly */
// import '@ionic/react/css/core.css';
// /* Basic CSS for apps built with Ionic */
// import '@ionic/react/css/normalize.css';
// import '@ionic/react/css/structure.css';
// import '@ionic/react/css/typography.css';
// /* Optional CSS utils that can be commented out */
// import '@ionic/react/css/padding.css';
// import '@ionic/react/css/float-elements.css';
// import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
// import '@ionic/react/css/flex-utils.css';
// import '@ionic/react/css/display.css';
// /* Theme variables */
// import './theme/variables.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
// const location = useLocation();
// React.useEffect(() => {
//     if ('serviceWorker' in navigator) {
//         navigator.serviceWorker.ready.then((registration) => {
//             registration.update();
//         });
//     }
// }, [location]);
// const updateAction = () => {
//     debugger
//     if ('serviceWorker' in navigator) {
//         navigator.serviceWorker.ready.then((registration) => {
//             if (registration.waiting) {
//
//                 // send the skip message to kick off the service worker install.
//                 registration.waiting.postMessage({type: 'SKIP_WAITING'});
//
//                 // add an listener to reload page when the new service worker is ready.
//                 registration.waiting.addEventListener('statechange', (event) => {
//                     const state =  event.target || {};
//                     if (state === 'activated') {
//                         window.location.reload();
//                     }
//                 });
//             }
//         });
//     }
// };
// useEffect(()=>{
//     updateAction()
// },[])

if('serviceWorker' in navigator){
    window.addEventListener('load',()=>{
        navigator.serviceWorker.register('service-worker.js')
    })
}
root.render(
  // <React.StrictMode>
        <MobileView/>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// serviceWorker.register();
reportWebVitals();
