import './App.css';

import imgProfile from './assets/imgProfile.png'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import React, {useEffect, useState,} from "react";
import {useMediaQuery} from "react-responsive";
import Mobile from "./mobile/Mobile";
import Web from "./Web/Web";
import style from "./mobile/Mobile.module.css";
import SplashScreen from "./components/splashScreen/SplashScreen";

import docMatinImg from "./assets/doctors/matin1.png";
import docBabekImg from "./assets/doctors/babek.png";
import docTebibImg from "./assets/doctors/tebib.jpeg";
import docAnarImg from "./assets/doctors/anar.jpeg";
import docPervizImg from "./assets/doctors/Perviz.png";
import docXatireImg from "./assets/doctors/Dr Xatire Sheyxzade.png";
import docRafigImg from "./assets/doctors/Dr Rafiq Bayramov.png";

const App = ()=> {
    const [isSplashLoaded, setSplashLoaded] =useState(true)
    const isBigScreen = useMediaQuery({query: '(min-width: 1824px)'})
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1223px)'})
    const is670px = useMediaQuery({query: '(min-width: 670px)'})
    const isPortraitMinSize = useMediaQuery({query: '(max-width: 575.98px)'})
    const isPortrait = useMediaQuery({query: '(orientation: portrait)'})
    const isRetina = useMediaQuery({query: '(min-resolution: 2dppx)'})
// 01251009GOal
    const [isChevronRight1, setIsChevronRight1] = useState(true)
    const [isChevronRight2, setIsChevronRight2] = useState(true)
    const [isChevronRight3, setIsChevronRight3] = useState(true)
    const [isChevronRight4, setIsChevronRight4] = useState(true)
    const [isChevronRight5, setIsChevronRight5] = useState(true)

    const [lang, setLang] = useState('AZ')
    const [isChild, setIsChild] = useState(false)
    const changeIsChild = (bool)=> {
        setActiveSimptomsIdList([])
        return setIsChild(bool)
    }

    const ourServicesDataAZ =
        {
            ourServicesHead1 : "Hybrid müayinə",
            ourServicesHead1Text : "İlkin olaraq pasient Sağlıkçı platformasına daxil olaraq şikayətinə uyğun həkim seçimi edir. Qeydiyyat və ödəniş mərhələsi tamamlandıqdan sonra qeyd olunmuş zaman üçün pasientə onlayn müayinə linki göndərilir. Pasient təyin olunmuş zamanda linkə daxil olaraq seçim etdiyi həkimin tibbi müayinəsini alır və müayinənin sonunda həkim icazə verilən resepti pasientə təqdim edə bilir. Əgər həkim diaqnostika üçün fiziki müayinə tövsiyyə edərsə, o zaman ikinci mərhələyə keçilir. Belə ki, pasient öz istəyinə uyğun planlaşdırılan zamanda klinikaya eyni həkimin qəbuluna dəvət olunur. Bu zaman pasientin əlavə ödəniş etməsinə ehtiyac qalmır.",
            ourServicesHead2 : "Ailə həkimi",
            ourServicesHead2Text : "Abunəlik xarakteri daşıyan bir xidmətdir. Xidmət şəxsin özünün və birinci dərəcəli ailə üzvlərinin, istisna hallarda isə digər üvzlərinin də tibbi qayğılarını əhatə edə bilər. İlk olaraq pasient Sağlıkçı platforması üzərindən müraciət edir. Ona uyğun terapevt təyin edilir. Daha sonra xidmətə uyğun ödənişi həyata keçirir. Ödəniş bir aylıq müddəti əhatə edir. Bu müddət ərzində pasient özü və birinci dərəcəli ailə üzvlərində yarana  biləcək tibbi problemlərlə bağlı mütəmadi ailə həkimi ilə əlaqə saxlaya bilər. Münasibətlər yalnız Sağlıkçı platforması vasitəsilə həyata keçirilir. Arzu edilərsə xidmət abunəlik sistemi vasitəsilə bir neçə aylıq və ya illik baş tuta bilər.",
            ourServicesHead5 : "Sağlıkçı ol",
            ourServicesHead5Text :  "Məqsədimiz və hədəfimiz 'Sağlıkçı ol' lahiyəsi çərçivəsində ölkəmizdə hər kəsə Amerika Ürək Assosayisanın (AHA) təlimatlarına əsaslanan ilk tibbi yardım təlimini keçmək, insanlarımızın ilk tibbi yardım gərəkdirən olası durumlara hazırlıqlı olmasını təmin etməkdir. Zamanında göstərilən doğru ilk tibbi yardım həyat qurtarır. Unutmayaq ki, ilk tibbi yardıma hər birimizin ehtiyacı ola bilər.  Siz də müraciət edərək ilkin tibbi yardımla bağlı təlimlərdən faydalana bilərsiniz.",
            aboutUsText : "Çağımızda zamanın ən azı sağlamlığımız qədər önəmli olmasını artıq hər kəs öz həyatının axışında hiss etməkdədir. Biz zamanın və eləcədə sağlamlığın bu önəmini başa düşərək, hər iki dəyəri biri birinin əleyhinə deyil, leyhinə çalışması üçün müasir həllər axtarışına çıxdıq. Bu dəyərləri  qorumaq, zamanımızın və sağlamlığımızın qeydinə qalmaq üçün biz “Sağlıkçı”-nı qurduq. “Sağlıkçı” sağlıq qayğılarımızı digital dünya ilə birləşdirən hibrid tibbi provayderdir. Bəzən ciddi, bəzən isə önəm daşımayan sağlıq şikayətlərimiz üçün uzun yollar qət etmək, şəhərlərin avtomobil tixaclarında qalmaq, uzun uzadı canlı növbələr gözləmək və ən əsası zamanımızı itirmək məcburiyyətində qalırıq. Sağlıkçı bizi bu kimi qayğılardan olduğumuz yeri tərk etmədən azad edir. Öncə platforma üzərindən müraciət etməklə sizə onlayn həkim müayinəsi xidməti göstərilir. Onlayn müayinənin kifayət etmədiyi, fiziki müayinə tələb olunduğu halda isə artıq siz əvvəlcədən təyin etdiyiniz zamanda klinikaya həkiminizin müayinəsinə dəvət olunursuz. Klinikada fiziki müayinə zamanı sizdən əlavə heç bir ödəniş tələb olunmur."
        }
    const ourServicesDataEN =
        {
            ourServicesHead1 : "Hybrid examination",
            ourServicesHead1Text : "Initially, the patient enters the Sağlıkçı platform and chooses a doctor according to his complaint. After the registration and payment stage is completed, an online examination link is sent to the patient for the specified time. The patient receives a medical examination of the doctor of his choice by visiting the link at the appointed time, and at the end of the examination, the doctor can provide the patient with an authorized prescription. If the doctor recommends a physical examination for diagnosis, then the second stage is taken. So, the patient is invited to the clinic at the scheduled time according to his wish to see the same doctor. In this case, the patient does not need to pay extra.",

            ourServicesHead2 : "Family doctor",
            ourServicesHead2Text : "It is a subscription service. The service can cover the medical care of the person himself and his first-degree family members, and in exceptional cases, other relatives as well. First, the patient applies through the Sağlıkçı platform. A suitable therapist is assigned to him. Then he makes the payment according to the service. The payment covers a period of one month. During this period, the patient can regularly contact the family doctor regarding medical problems that may arise in him and his first-degree family members. Relationships are made only through the Sağlıçı platform. If desired, the service can take place for several months or years through the subscription system.",
            ourServicesHead5 : "Become a Sağlıkçı",
            ourServicesHead5Text :  "Our aim and goal is to provide first aid training based on the guidelines of the American Heart Association (AHA) to everyone in our country within the framework of the 'Become a Sağlıkçı' project, to ensure that our people are prepared for possible situations that require first aid. Correct first aid provided on time saves life. Let's not forget that each of us may need first aid. You can also apply for first aid training.",
            aboutUsText : "In our age, everyone feels that time is at least as important as our health. Understanding the importance of time and health, we set out to find modern solutions to make both values ​​work for each other, not against each other. In order to protect these values, to take care of our time and health, we founded Sağlıkçı. Sağlıkçı is a hybrid medical provider that combines our healthcare with the digital world. Sometimes we have to travel long distances, stay in traffic jams of cities, wait in long live queues and most importantly waste our time for our health complaints, sometimes serious and sometimes trivial. The healer frees us from such worries without leaving the place where we are. First, by applying through the platform, you will be provided with an online medical examination service. If the online examination is not enough, and if a physical examination is required, you are already invited to the clinic for an examination by your doctor at a pre-arranged time. During the physical examination at the clinic, you are not required to pay any additional fees."
        }

    const ourServicesDataRU =
        {
            ourServicesHead1 : "Гибридное обследование",
            ourServicesHead1Text : "Первоначально пациент заходит на платформу Саглыкчи и выбирает врача по своей жалобе. После прохождения этапа регистрации и оплаты пациенту на указанное время отправляется ссылка на онлайн-обследование. Пациент получает медицинский осмотр у выбранного им врача, посетив ссылку в назначенное время, а по окончании осмотра врач может выдать пациенту авторизованный рецепт. Если врач рекомендует физикальное обследование для постановки диагноза, то прибегают ко второму этапу. Итак, пациента приглашают в клинику в назначенное время по его желанию обратиться к тому же врачу. В этом случае пациенту не нужно доплачивать.",

            ourServicesHead2 : "Семейный врач",
            ourServicesHead2Text : "Это услуга по подписке. Услуга может охватывать медицинское обслуживание самого человека и членов его семьи первой степени родства, а в исключительных случаях и других родственников. Сначала пациент подает заявку через платформу Саглыкчи. Ему назначается подходящий терапевт. Затем он производит оплату согласно услуге. Оплата производится в течение одного месяца. В этот период пациент может регулярно обращаться к семейному врачу по поводу проблем со здоровьем, которые могут возникнуть у него и членов его семьи первой степени родства. Отношения осуществляются только через платформу Саглыкчи. При желании услуга может проходить в течение нескольких месяцев или лет по системе подписки.",
            ourServicesHead5 : "Стать Саглыкчи",
            ourServicesHead5Text : "Наша цель и задача — провести обучение по оказанию первой помощи на основе рекомендаций Американской кардиологической ассоциации (AHA) для всех жителей нашей страны в рамках проекта «Стать Саглыкчи», чтобы гарантировать, что наши люди готовы к возможным ситуациям, требующим первая помощь. Правильная и вовремя оказанная первая помощь спасает жизнь. Не будем забывать, что каждому из нас может понадобиться первая помощь. Вы также можете подать заявку на обучение оказанию первой помощи.",
            aboutUsText : "В наш век каждый чувствует, что время не менее важно, чем наше здоровье. Понимая важность времени и здоровья, мы задались целью найти современные решения, позволяющие обеим ценностям работать друг на друга, а не друг против друга. Чтобы защитить эти ценности, позаботиться о нашем времени и здоровье, мы основали Саглыкчи. Саглыкчи — это гибридный поставщик медицинских услуг, который объединяет наше здравоохранение с цифровым миром.Иногда нам приходится преодолевать большие расстояния, стоять в городских пробках, стоять в длинных очередях и, самое главное, тратить время на жалобы на здоровье, иногда серьезные, а иногда и пустяковые. Целитель освобождает нас от таких забот, не покидая того места, где мы находимся. Во-первых, подав заявку через платформу, вам будет предоставлена ​​услуга онлайн-медицинского осмотра. Если онлайн-обследования недостаточно и требуется медицинский осмотр, вас уже приглашают в клинику на осмотр к врачу в заранее назначенное время. Во время медицинского осмотра в клинике от вас не требуется платить никаких дополнительных сборов."
        }

    const languagesArr = [
        {
            "langKey": "EN",
            "langValues":
                {
                    navbarMenu:
                        {
                            doctors: "Doctors",
                            ourServices: "Our Services",
                            abouUs: "About Us",
                            contact: "Contact Us",
                            searchPlaceholder: "Search doctor or simptom",
                            shareSaglikci :"Share Sağlıkçı",
                            sosText :"You can call the private emergency medical service of Sağlıcı clinic as soon as possible.",
                            sosBtnText:"Make a call"
                        },
                    body: {
                        doctors: {
                            header:'Our doctors',
                            allDoctors:'All Doctors',
                            getInformation:'Get Information'
                        },
                        simptoms: {
                            header: isPortrait ? 'Simptoms' :'Arrange by simptoms',
                            child:'Child',
                            adult:'Adult',
                            allSimptoms:'All Simptoms'
                        },
                        ourServices: {
                            header: 'Our services',
                            data : ourServicesDataEN
                        },
                        partners:{
                            header:"Partners"
                        },
                        aboutUs:{
                            header:"About us",
                            description:"In our age, everyone feels that time is no less important than our health. Understanding the importance of time and health, we set out to find modern solutions that allow both values ​​to work for each other, and not against each other. To protect these values,\n" +
                                "We founded \"Sağlıkçı\" to take care of our time and health.\n" +
                                "Sağlıkçı is a hybrid healthcare provider that connects our healthcare with the digital world. Traveling long distances, stuck in city traffic jams due to our sometimes serious and sometimes minor health complaints,\n" +
                                "we have to wait in long lines and, most importantly, waste time.\n" +
                                "A medical professional frees us from such worries without leaving the place where we are. First, by leaving a request on the platform, you will be provided with an online medical examination service. Online exam is not enough\n" +
                                "If a medical examination is required, you are already invited to the clinic for an examination by a doctor at a pre-arranged time.\n" +
                                "You are not required to pay any additional fees during your medical examination at the clinic."
                        },
                        contacts:{
                            header :"Contacts",
                            workTimeHeader :"Working hours",
                            workTime:"09:00 - 18:00",
                            description:"Excluding Sundays",
                            description2nd : "and non-working days "
                        }
                    }
                }
        },
        {
            "langKey": "AZ",
            "langValues":
                    {
                    navbarMenu:
                        {
                            doctors: "Həkimlər",
                            ourServices: "Xidmətlər",
                            abouUs: "Haqqımızda",
                            contact: "Əlaqə",
                            searchPlaceholder: "Həkim və ya simptom axtar",
                            shareSaglikci :"Sağlıkçını paylaş",
                            sosText :"Siz ən qısa zamanda Sağlıkçı klinikasının özəl təcili tibbi yardım xidmətinə çağırış edə bilərsiniz.",
                            sosBtnText:"Çağırış et"
                        },
                    body: {
                        doctors: {
                            header:'Həkimlərimiz',
                            allDoctors:'Bütün həkimlər',
                            getInformation:'Məlumat al'
                        },
                        simptoms: {
                            header: isPortrait ? 'Simptomlar' :'Simptomlara görə sırala',
                            child:'Uşaq',
                            adult:'Böyük',
                            allSimptoms:'Bütün simptomlar'
                        },
                        ourServices: {
                            header: 'Xidmətlər',
                            data : ourServicesDataAZ
                        },
                        partners:{
                            header:"Tərəfdaşlarımız"
                        },
                        aboutUs:{
                            header:"Haqqımızda",
                            description:'Biz zamanın və sağlamlığın önəmini başa düşərək, hər iki dəyəri biri birinin əleyhinə deyil, leyhinə çalışması üçün müasir həllər axtarışına çıxdıq. Bu dəyərləri  qorumaq, zamanımızın və sağlamlığımızın qeydinə qalmaq üçün biz “Sağlıkçı”-nı qurduq. “Sağlıkçı” sağlıq qayğılarımızı rəqəmsal dünya ilə birləşdirən hibrid tibbi platformadır. Bəzən ciddi, bəzən isə önəm daşımayan sağlıq şikayətlərimiz üçün uzun yollar qət etmək, şəhərlərin avtomobil tixaclarında qalmaq, uzun uzadı canlı növbələr gözləmək və ən əsası zamanımızı itirmək məcburiyyətində qalırıq. Biz sizi bu kimi qayğılardan olduğunuz yeri tərk etmədən azad edirik. Öncə platforma üzərindən müraciət etməklə sizə onlayn həkim müayinəsi verilir. Onlayn müayinənin kifayət etmədiyi, fiziki müayinə tələb olunduğu halda isə artıq siz əvvəlcədən təyin olunan zamanda klinikaya həkiminizin müayinəsinə dəvət olunursuz. Klinikada fiziki müayinə zamanı sizdən əlavə heç bir ödəniş tələb olunmur.\n' +
                                '\n' +'Siyasətimiz\n' +
                                ' - Daha sağlam və inkişaf etmiş gələcək üçün özümüzə xas sistemimizlə, Azərbaycanda və dünyada pasiyentlərimizin rəqəmsallaşma yollarına dəyər qatmaq və həyatlarını asanlaşdırmaq.',
                        },
                        contacts:{
                            header :"Əlaqə",
                            workTimeHeader :"İş saatları",
                            workTime:"Saat 09:00 - 18:00",
                            description:"Bazar və qeyri iş günləri ",
                            description2nd : "istisna olmaqla"
                        }
                    }
                }
        },
        {
            "langKey": "RU",
            "langValues": {
                navbarMenu:
                    {
                        doctors: "Доктора",
                        ourServices: "Услуги",
                        abouUs: "О нас",
                        contact: "Контакты",
                        searchPlaceholder: "Ru Search doctor or simptom",
                        shareSaglikci :"Поделиться Sağlıkçı",
                        sosText :"Вы можете позвонить в частную службу неотложной медицинской помощи клиники Саглыкчи как можно скорее.",
                        sosBtnText:"Сделать вызов"
                    },
                body: {
                    doctors: {
                        header:'Наши доктора',
                        allDoctors:'Все доктора',
                        getInformation:'Получить информацию'
                    },
                    simptoms: {
                        header: isPortrait ? 'Симптомы' :'Распределить по симптомам',
                        child:'Дети',
                        adult:'Взрослые',
                        allSimptoms:'Все симптомы'
                    },
                    ourServices: {
                        header: 'Наши услуги',
                        data : ourServicesDataRU
                    },
                    partners:{
                        header:"Партнеры"
                    },
                    aboutUs:{
                        header:"О нас",
                        description:"В наш век каждый чувствует, что время не менее важно, чем наше здоровье. Понимая важность времени и здоровья, мы задались целью найти современные решения, позволяющие обеим ценностям работать друг на друга, а не друг против друга. Чтобы защитить эти ценности,\n" +
                            "Мы основали «Sağlıkçı», чтобы следить за своим временем и здоровьем. Sağlıkçı — это гибридный поставщик медицинских услуг, который объединяет наше здравоохранение с цифровым миром. Путешествуя на дальние расстояния, стоя в городских пробках из-за наших иногда серьезных, а иногда и незначительных жалоб на здоровье,\n" +
                            "нам приходится ждать в длинных очередях и, самое главное, тратить время. Медицинский работник освобождает нас от таких забот, не покидая места, где мы находимся.Во-первых, оставив заявку на платформе, вам будет предоставлена ​​услуга онлайн-прохождения медицинского обследования. Онлайн-экзамена недостаточно,\n" +
                            "если требуется медицинский осмотр, вас уже приглашают в клинику на осмотр к врачу в заранее назначенное время. Во время медицинского осмотра в клинике с вас не требуется платить никаких дополнительных сборов."
                    },
                    contacts:{
                        header :"Контакты",
                        workTimeHeader :"Рабочее время",
                        workTime:"09:00 - 18:00",
                        description:"Кроме воскресенья",
                        description2nd : "и нерабочих дней"
                    }
                }
            }
        }]
    const siteDataAz = [
        {
            "doctors":
                [
                    {
                        "id": 1,
                        "fullname": "Anar Nəcəbov",
                        "medicalField": 12,
                        "practicYear": "5",
                        "image":docAnarImg,
                        'position':'Baş direktor',
                        "description": "Azərbaycan Tibb Universiteti Tədris Cərrahi Korpus - da  \"Uroloq-androloq\" ixtisası üzrə rezidentura (2020-2024-cü il)\n" +
                            "Azərbaycan Tibb Universiteti Müalicə-profilaktika fakültəsində \"Müalicə işi\" ixtisası (2005-2011-ci)"
                    },
                    {
                        "id": 2,
                        "fullname": "Mətin Mahmudov",
                        "medicalField": 1,
                        "practicYear": "8",
                        "image":docMatinImg,
                        'position':'Tibbi direktor',
                        "description": "Azərbaycan Tibb Universiteti Müalicə-profilaktika fakültəsində \"Müalicə işi\" ixtisası (2005-2011-ci)"
                    },

                    {
                        "id": 3,
                        "fullname": "Pərviz Dadaşov",
                        "medicalField": 16,
                        "practicYear": "13",
                        "image":docPervizImg,
                        'position':'Məlhəm Beynəlxalq Hospital',
                        "description": "Türkiyədə Dermatoveneraloqiya və Kosmetaloqiya üzrə ixtisas artırma kursları (2014-2019-cu il)\n" +
                            "Azərbaycan Tibb Universiteti Müalicə-profilaktika fakültəsində \"Müalicə işi\" ixtisası- Dermatoveneroloq (2004-2011-ci il)"
                    },
                    {
                        "id": 4,
                        "fullname": "Xatirə Şeyx-zadə",
                        "medicalField": 13,
                        "practicYear": "12",
                        "image":docXatireImg,
                        'position':'Məlhəm Beynəlxalq Hospital',
                        "description": "Həkimlərin Təkmilləşdirmə İnstitutunun bazası olan Mir-Qasımov adına Respublika Klinik Xəstəxanasında Mamalıq - ginekologiya üzrə Rezidentura təhsili (2011- 2016-cı il)\n" +
                            "Azərbaycan Tibb Universiteti Müalicə-profilaktika fakültəsində \"Müalicə işi\" ixtisası (2005-2011-ci il)"
                    },
                    {
                        "id": 5,
                        "fullname": "Rafiq Bayramov",
                        "medicalField": 3,
                        "practicYear": "24",
                        "image":docRafigImg,
                        "position":'Azərbaycan Tibb Universitetinin “Ağ ciyər xəstəlikləri” kafedrasının dosenti,\n' +
                            'Tibb üzrə Fəlsəfə Doktoru',
                        "description": "1993–1999-cu illərdə Azərbaycan Tibb Universitetinin pediatriya fakultəsində təhsil almışdır.\n" +
                            "2003-cü ildə Azərbaycan Tibb Universitetinin ağ ciyər xəstəlikləri kafedrasına baş laborant,\n" +
                            "2004-cü ildə assistent, 2013-cü ildə isə dosent vəzifəsinə təyin olunmuşdur.\n" +
                            "2011-ci ildə N.Ə.Abbasovun elmi rəhbərliyi ilə “Xroniki destruktiv ağ ciyər vərəmi zamanı ürəyin funksional vəziyyəti” mövzusunda namizədlik dissertasiyası müdafiə etmiş və tibb üzrə fəlsəfə doktoru alimlik dərəcəsi almışdır\n" +
                            "R.İ.Bayramov 50-dən çox elmi məqalənin müəllifidir. Azərbaycan Tibb Universitetinin “Ağ ciyər xəstəlikləri” kafedrasının dosenti, tibb üzrə fəlsəfə doktorudur.\n" +
                            "2015-ci ildə Rafiq Bayramov  Azərbaycan Tibb Universitetinin “Ağ ciyər xəstəlikləri” kafedrasının müdiri vəzifəsinə seçilmişdir."
                    },
                    // {
                    //     "id": 6,
                    //     "fullname": "Mətinli Mahmud",
                    //     "medicalField": 1,
                    //     "practicYear": "8",
                    //     "image":null,
                    //     "description": "2000-ci ildə ATU-ni bitirib, 2000-2005-ci illərdə GATA-da kardioloji bölümündə uzmanlıq təhsili alıb."
                    // },
                    // {
                    //     "id": 7,
                    //     "fullname": "Təbibli Məmməd",
                    //     "medicalField": 2,
                    //     "practicYear": "5",
                    //     "image":null,
                    //     "description": "2000-ci ildə ATU-ni bitirib, 2000-2005-ci illərdə GATA-da kardioloji bölümündə uzmanlıq təhsili alıb."
                    // },
                    // {
                    //     "id": 8,
                    //     "fullname": "Nurlan Muradov",
                    //     "medicalField": 3,
                    //     "practicYear": "2",
                    //     "image":null,
                    //     "description": "2000-ci ildə ATU-ni bitirib, 2000-2005-ci illərdə GATA-da kardioloji bölümündə uzmanlıq təhsili alıb."
                    // },
                    // {
                    //     "id": 9,
                    //     "fullname": "Şəbnam Əliyev",
                    //     "medicalField": 6,
                    //     "practicYear": "7",
                    //     "image":null,
                    //     "description": "2000-ci ildə ATU-ni bitirib, 2000-2005-ci illərdə GATA-da kardioloji bölümündə uzmanlıq təhsili alıb."
                    // },
                    // {
                    //     "id": 10,
                    //     "fullname": "Şəbnam Əliyev",
                    //     "medicalField": 5,
                    //     "practicYear": "7",
                    //     "image":null,
                    //     "description": "2000-ci ildə ATU-ni bitirib, 2000-2005-ci illərdə GATA-da kardioloji bölümündə uzmanlıq təhsili alıb."
                    // },
                    // {
                    //     "id": 11,
                    //     "fullname": "Şəbnam Əliyev",
                    //     "medicalField": 5,
                    //     "practicYear": "7",
                    //     "image":null,
                    //     "description": "2000-ci ildə ATU-ni bitirib, 2000-2005-ci illərdə GATA-da kardioloji bölümündə uzmanlıq təhsili alıb."
                    // },
                    // {
                    //     "id": 12,
                    //     "fullname": "Şəbnam Əliyev",
                    //     "medicalField": 6,
                    //     "practicYear": "7",
                    //     "image":null,
                    //     "description": "2000-ci ildə ATU-ni bitirib, 2000-2005-ci illərdə GATA-da kardioloji bölümündə uzmanlıq təhsili alıb."
                    // }
                ]

        },
        {
            "doctorsChild":
                [
                    {
                        "id": 1,
                        "fullname": "Babək Abbasov",
                        "medicalField": 1,
                        "practicYear": "8",
                        "image":docBabekImg,
                        "description": "Erciyes Üniversiteti Tibb Fakültesi Uşaq Sağlamlığı və Xəstəlikləri  A.D.(2017 -2022-ci illər )\n" +
                            "Özəl Muş Şifa Hospitalı (2023-cü ildən)"
                    }
                    // {
                    //     "id": 4,
                    //     "fullname": "XXNəsimi Paşayev",
                    //     "medicalField": 7,
                    //     "practicYear": "2",
                    //     "image":docNasimi,
                    //     "description": "2000-ci ildə ATU-ni bitirib, 2000-2005-ci illərdə GATA-da kardioloji bölümündə uzmanlıq təhsili alıb."
                    // },
                    // {
                    //     "id": 5,
                    //     "fullname": "XXŞəbnam Əliyev",
                    //     "medicalField": 8,
                    //     "practicYear": "7",
                    //     "image":null,
                    //     "description": "2000-ci ildə ATU-ni bitirib, 2000-2005-ci illərdə GATA-da kardioloji bölümündə uzmanlıq təhsili alıb."
                    // },
                    // {
                    //     "id": 6,
                    //     "fullname": "XXMətinli Mahmud",
                    //     "medicalField": 1,
                    //     "practicYear": "8",
                    //     "image":null,
                    //     "description": "2000-ci ildə ATU-ni bitirib, 2000-2005-ci illərdə GATA-da kardioloji bölümündə uzmanlıq təhsili alıb."
                    // },
                    // {
                    //     "id": 7,
                    //     "fullname": "XXTəbibli Məmməd",
                    //     "medicalField": 2,
                    //     "practicYear": "5",
                    //     "image":null,
                    //     "description": "2000-ci ildə ATU-ni bitirib, 2000-2005-ci illərdə GATA-da kardioloji bölümündə uzmanlıq təhsili alıb."
                    // }
]
        },
        {
            "simptoms":
                [
                    {"name": "Yüksək hərarət", "id": 1,  "medId": [1]},
                    {"name": "Boğaz ağrısı", "id": 2,  "medId": [1,9]},
                    {"name": "Baş ağrısı, Miqren", "id": 3,  "medId": [1,7]},
                    {"name": "Oynaq ağrısı", "id": 4,  "medId": [14,1]},
                    {"name": "Bel \nağrısı", "id": 5,  "medId": [7,1]},
                    {"name": "Travma ağrısı", "id": 6,  "medId": [14,1]},
                    {"name": "Üzdə səpki", "id": 7,  "medId": [15,16]},
                    {"name": "Soyuqdəymə", "id": 8,  "medId": [1]},
                    {"name": "Allergik səpki", "id": 9,  "medId": [17,16,1]},
                    {"name": "Dəri xəstəliyi", "id": 10,  "medId": [16]},
                    {"name": "Hamiləlik", "id": 11,  "medId": [13]},
                    {"name": "Menstruasiya", "id": 12,  "medId": [13]},
                    {"name": "Cinsi həyat", "id": 13,  "medId": [12]},
                    {"name": "Analizi göstər", "id": 14,  "medId": [1]},
                    {"name": "Sinədə ağrı", "id": 15,  "medId": [5,1]},
                    {"name": "Sidik yolları xəstəliyi", "id": 16,  "medId": [12]},
                    {"name": "Burun tutulması", "id": 17,  "medId": [1,9]},
                    {"name": "Kontraseptiv", "id": 18,  "medId": [13]},
                    {"name": "Qulaq ağrısı", "id": 19,  "medId": [9,1]},
                    {"name": "İşdə gərginlik", "id": 20,  "medId": [18]},
                    {"name": "Mədə-bağırsaq", "id": 21,  "medId": [6,1]},
                    {"name": "Aşağı immunitet", "id": 22,  "medId": [17,1]},
                    {"name": "Hormonal dəyişiklik", "id": 23,  "medId": [8]},
                    {"name": "Başqa simptom", "id": 24,  "medId": [1]},
                    {"name": "Arterial təzyiq", "id": 25,  "medId": [5]},
                    {"name": "Şəkərli diabet", "id": 26,  "medId": [8,1]},
                    {"name": "Arıqlama kökəlmə", "id": 27,  "medId": [19]},
                    {"name": "Revmatizm", "id": 28,  "medId": [20]},
                    {"name": "D vitamin azlığı", "id": 29,  "medId": [1]},
                    {"name": "Baş gicəllənməsi", "id": 30,  "medId": [7]},
                ]
        },
        {
            "simptomsChild":
                [
                    {"name": "Boğazda ağrı", "id": 1,  "medId": [1,2]},
                    {"name": "Yüksək hərarət", "id": 2,  "medId": [1]},
                    {"name": "Uşaq infeksiyası", "id": 3,  "medId": [1]},
                    {"name": "Narahat yuxu", "id": 4,  "medId": [1,3]},
                    {"name": "Mədə bağırsaq", "id": 5,  "medId": [4,1]},
                    {"name": "Uşaqda səpki", "id": 6,  "medId": [7,1]},
                    {"name": "Analizi göstər", "id": 7,  "medId": [1]},
                    {"name": "Fiziki inkişaf", "id": 8,  "medId": [1]},
                    {"name": "İnkişafdan qalma", "id": 9,  "medId": [1,8]},
                    {"name": "Başqa sual", "id": 10,  "medId": [1]},
                ]
        }
        ,
        {
            "medicalField":
                [
                    {"name": "Terapevt", "id": 1},
                    {"name": "İnfeksionist", "id": 2},
                    {"name": "Pulmanoloq", "id": 3},
                    {"name": "Fitiziatr", "id": 4},
                    {"name": "Kardioloq", "id": 5},
                    {"name": "Qastroentroloq", "id": 6},
                    {"name": "Nevropatoloq", "id": 7},
                    {"name": "Endokrinoloq", "id": 8},
                    {"name": "Lor", "id": 9},
                    {"name": "Alqoloq", "id": 10},
                    {"name": "Ümumi cərrah", "id": 11},
                    {"name": "Uroloq-androloq", "id": 12},
                    {"name": "Ginekoloq", "id": 13},
                    {"name": "Travmatoloq-ortoped", "id": 14},
                    {"name": "Kosmetoloq", "id": 15},
                    {"name": "Dermotoloq", "id": 16},
                    {"name": "Allerqoloq-immunoloq", "id": 17},
                    {"name": "Psixoloq", "id": 18},
                    {"name": "Dietoloq", "id": 19},
                    {"name": "Revmatoloq", "id": 20},
                ]
        },
        {
            "medicalFieldChild":
                [
                    {"name": "Pediatr", "id": 1},
                    {"name": "Lor", "id": 2},
                    {"name": "Nevropatoloq", "id": 3},
                    {"name": "Qastroentroloq", "id": 4},
                    {"name": "Kardioloq", "id": 5},
                    {"name": "Qastroentroloq", "id": 6},
                    {"name": "Dermotoloq", "id": 7},
                    {"name": "Endokrinoloq", "id": 8}
                ]
        }
    ];
    const siteDataRu = [
        {
            "doctors":
                [
                    {
                        "id": 1,
                        "fullname": "Anar Nəcəbov",
                        "medicalField": 12,
                        "practicYear": "5",
                        "image":docAnarImg,
                        'position':'Baş direktor',
                        "description": "Ординатура по специальности «Уролог-андролог» в Учебно-хирургическом корпусе Азербайджанского Медицинского Университета (2020-2024) Специальность «Лечебное дело» на факультете Медицины и Профилактики Азербайджанского Медицинского Университета (2005-2011)"
                    },
                    {
                        "id": 2,
                        "fullname": "Mətin Mahmudov",
                        "medicalField": 1,
                        "practicYear": "5",
                        "image":docMatinImg,
                        'position':'Tibbi direktor',
                        "description": "Специальность «Лечебное дело» на лечебно-профилактическом факультете Азербайджанского Медицинского Университета (2005-2011 гг.)"
                    },
                    {
                        "id": 3,
                        "fullname": "Pərviz Dadaşov",
                        "medicalField": 16,
                        "practicYear": "13",
                        "image":docPervizImg,
                        'position':'Məlhəm Beynəlxalq Hospital',
                        "description": "Курсы повышения квалификации по дерматовенерологии и косметологии в Турции (2014-2019)\n" +
                            "Специальность «Лечебное дело» Лечебно-Профилактического факультета Азербайджанского Медицинского Университета – врач-дерматовенеролог (2004-2011)."
                    },
                    {
                        "id": 4,
                        "fullname": "Xatirə Şeyx-zadə",
                        "medicalField": 13,
                        "practicYear": "12",
                        "image":docXatireImg,
                        'position':'Məlhəm Beynəlxalq Hospital',
                        "description": "Клиническая ординатура по акушерству и гинекологии в Республиканской клинической больнице имени Мир-Гасымова, которая является базой Института усовершенствования врачей (2011-2016)\n" +
                        "Специальность «Лечебное дело» на лечебно-профилактическом факультете Азербайджанского Медицинского Университета (2005-2011)"
                    },
                    {
                        "id": 5,
                        "fullname": "Rafiq Bayramov",
                        "medicalField": 3,
                        "practicYear": "24",
                        "image":docRafigImg,
                        "position":'Azərbaycan Tibb Universitetinin “Ağ ciyər xəstəlikləri” kafedrasının dosenti,\n' +
                            'Tibb üzrə Fəlsəfə Doktoru',
                        "description": "В 1993-1999 годах учился на педиатрическом факультете Азербайджанского Медицинского Университета.\n" +
                            "В 2003 году главный лаборант кафедры болезней легких Азербайджанского медицинского университета\n" +
                            "Он был назначен ассистентом в 2004 году и доцентом в 2013 году.\n" +
                            "В 2011 году под научным руководством Н.А.Аббасова защитил кандидатскую диссертацию на тему Функциональное состояние сердца при хроническом деструктивном туберкулезе легких и получил степень доктора философии в медицине\n" +
                            "Р.И.Байрамов является автором более 50 научных статей. Он является доцентом кафедры Легочные болезни Азербайджанского Медицинского Университета, доктором философии в медицине.\n" +
                             "В 2015 году Рафиг Байрамов был избран на должность заведующего кафедрой «Легочные болезни» Азербайджанского медицинского университета."
                    },
                ]

        },
        {
            "doctorsChild":
                [
                    {
                        "id": 1,
                        "fullname": "Babək Abbasov",
                        "medicalField": 1,
                        "practicYear": "8",
                        "image":docBabekImg,
                        "description": "Медицинский факультет детского здоровья и болезней Университета Эрджиес AD (2017-2022 годы) «Частная больница Муш Шифа (с 2023 г.)"
                    }
                ]
        },
        {
            "simptoms":
                [
                    {"name": "Высокая температура", "id": 1, "medId": [1]},
                    {"name": "Боль в горле", "id": 2, "medId": [1,9]},
                    {"name": "Головная боль, Мигрень", "id": 3, "medId": [1,7]},
                    {"name": "Боль в суставах", "id": 4, "medId": [14,1]},
                    {"name": "Боль в пояснице", "id": 5, "medId": [7,1]},
                    {"name": "Травматическая боль", "id": 6, "medId": [14,1]},
                    {"name": "Сыпь на лице", "id": 7, "medId": [15,16]},
                    {"name": "Простуда", "id": 8, "medId": [1]},
                    {"name": "Аллергическая сыпь", "id": 9, "medId": [17,16,1]},
                    {"name": "Кожное заболевание", "id": 10, "medId": [16]},
                    {"name": "Беременность", "id": 11, "medId": [13]},
                    {"name": "Менструация", "id": 12, "medId": [13]},
                    {"name": "Сексуальная жизнь", "id": 13, "medId": [12]},
                    {"name": "Показать анализ", "id": 14, "medId": [1]},
                    {"name": "Боль в груди", "id": 15, "medId": [5,1]},
                    {"name": "Заболевание мочевыводящих путей", "id": 16, "medId": [12]},
                    {"name": "Заложенность носа", "id": 17, "medId": [1,9]},
                    {"name": "Контрацептив", "id": 18, "medId": [13]},
                    {"name": "Боль в ухе", "id": 19, "medId": [9,1]},
                    {"name": "Стресс на работе", "id": 20, "medId": [18]},
                    {"name": "Желудочно-кишечный", "id": 21, "medId": [6,1]},
                    {"name": "Низкий иммунитет", "id": 22, "medId": [17,1]},
                    {"name": "Гормональные изменения", "id": 23, "medId": [8]},
                    {"name": "Другой симптом", "id": 24, "medId": [1]},
                    {"name": "Артериальное давление", "id": 25, "medId": [5]},
                    {"name": "Диабет", "id": 26, "medId": [8,1]},
                    {"name": "Похудение", "id": 27, "medId": [19]},
                    {"name": "Ревматизм", "id": 28, "medId": [20]},
                    {"name": "Дефицит витамина D", "id": 29, "medId": [1]},
                    {"name": "Головокружение", "id": 30, "medId": [7]},
                ]
        },
        {
            "simptomsChild":
                [
                    {"name": "Боль в горле", "id": 1, "medId": [1,2]},
                    {"name": "Высокая температура", "id": 2, "medId": [1]},
                    {"name": "Детская инфекция", "id": 3, "medId": [1]},
                    {"name": "Нарушенный сон", "id": 4, "medId": [1,3]},
                    {"name": "Желудочно-кишечный", "id": 5, "medId": [4,1]},
                    {"name": "Детская сыпь", "id": 6, "medId": [7,1]},
                    {"name": "Показать анализ", "id": 7, "medId": [1]},
                    {"name": "Физическое развитие", "id": 8, "medId": [1]},
                    {"name": "Деградация", "id": 9, "medId": [1,8]},
                    {"name": "Еще один вопрос", "id": 10, "medId": [1]},
                ]
        }
        ,
        {
            "medicalField":
                [
                    {"name": "Терапевт", "id": 1},
                    {"name": "Инфекционист", "id": 2},
                    {"name": "Пульманолог", "id": 3},
                    {"name": "Фитизиатр", "id": 4},
                    {"name": "Кардиолог", "id": 5},
                    {"name": "Гастроэнтеролог", "id": 6},
                    {"name": "Невропатолог", "id": 7},
                    {"name": "Эндокринолог", "id": 8},
                    {"name": "Лор", "id": 9},
                    {"name": "Алголог", "id": 10},
                    {"name": "Главный хирург", "id": 11},
                    {"name": "Уролог-андролог", "id": 12},
                    {"name": "Гинеколог", "id": 13},
                    {"name": "Травматолог-ортопед", "id": 14},
                    {"name": "Косметолог", "id": 15},
                    {"name": "Дермотолог", "id": 16},
                    {"name": "Аллерголог-иммунолог", "id": 17},
                    {"name": "Психолог", "id": 18},
                    {"name": "Диетолог", "id": 19},
                    {"name": "Ревматолог", "id": 20},
                ]
        },
        {
            "medicalFieldChild":
                [
                    {"name": "Педиатр", "id": 1},
                    {"name": "Лор", "id": 2},
                    {"name": "Невропатолог", "id": 3},
                    {"name": "Гастроэнтеролог", "id": 4},
                    {"name": "Кардиолог", "id": 5},
                    {"name": "Гастроэнтеролог", "id": 6},
                    {"name": "Дермотолог", "id": 7},
                    {"name": "Эндокринолог", "id": 8}
                ]
        }
    ];
    const siteDataEn = [
        {
            "doctors":
                [
                    {
                        "id": 1,
                        "fullname": "Anar Nəcəbov",
                        "medicalField": 12,
                        "practicYear": "5",
                        "image":docAnarImg,
                        'position':'Baş direktor',
                        "description": "Residency on the specialty Urologist-Andrologist at Azerbaijan Medical University Teaching Surgical Corps (2020-2024)\n" +
                            "Treatment work specialty at the Faculty of Medicine and Prevention of Azerbaijan Medical University (2005-2011)"
                    },
                    {
                        "id": 2,
                        "fullname": "Mətin Mahmudov",
                        "medicalField": 1,
                        "practicYear": "8",
                        "image":docMatinImg,
                        'position':'Tibbi direktor',
                        "description": "\"Treatment work\" specialty at the Faculty of Treatment and Prevention of Azerbaijan Medical University (2005-2011)"
                    },
                    {
                        "id": 3,
                        "fullname": "Pərviz Dadaşov",
                        "medicalField": 16,
                        "practicYear": "13",
                        "image":docPervizImg,
                        'position':'Məlhəm Beynəlxalq Hospital',
                        "description": "Advanced courses in dermatovenerology and cosmetology in Turkey (2014-2019)\n" +
                            "'Treatment work' specialty at the Faculty of Treatment and Prevention of Azerbaijan Medical University - Dermatovenereologist (2004-2011)"
                    },
                    {
                        "id": 4,
                        "fullname": "Xatirə Şeyx-zadə",
                        "medicalField": 13,
                        "practicYear": "12",
                        "image":docXatireImg,
                        'position':'Məlhəm Beynəlxalq Hospital',
                        "description": "Residency training in Obstetrics and Gynecology at the Mir-Gasimov Republican Clinical Hospital, which is the base of the Institute for the Advancement of Doctors (2011-2016)\n" +
                            "Treatment work specialty at the Faculty of Treatment and Prevention of Azerbaijan Medical University (2005-2011)"
                    },
                    {
                        "id": 5,
                        "fullname": "Rafiq Bayramov",
                        "medicalField": 3,
                        "practicYear": "24",
                        "image":docRafigImg,
                        "position":'Azərbaycan Tibb Universitetinin “Ağ ciyər xəstəlikləri” kafedrasının dosenti,\n' +
                            'Tibb üzrə Fəlsəfə Doktoru',
                        "description": "In 1993-1999, he studied at the Faculty of Pediatrics of the Azerbaijan Medical University.\n" +
                            "In 2003, chief laboratory assistant at the Department of Lung Diseases of the Azerbaijan Medical University,\n" +
                            "He was appointed assistant in 2004, and associate professor in 2013.\n" +
                            "In 2011, under the scientific guidance of N.A. Abbasov, he defended his candidate's thesis on the topic Functional state of the heart during chronic destructive pulmonary tuberculosis and received the degree of doctor of philosophy in medicine\n" +
                            "R.I. Bayramov is the author of more than 50 scientific articles. He is an associate professor of the Lung diseases department of the Azerbaijan Medical University, a doctor of philosophy in medicine.\n" +
                            "In 2015, Rafig Bayramov was elected to the position of head of the Lung diseases department of the Azerbaijan Medical University."
                    }
                ]

        },
        {
            "doctorsChild":
                [
                    {
                        "id": 1,
                        "fullname": "Babək Abbasov",
                        "medicalField": 1,
                        "practicYear": "8",
                        "image":docBabekImg,
                        "description": "Erciyes University Faculty of Medicine Children's Health and Diseases A.D. (2017-2022 years)\n" +
                            "Private Mush Shifa Hospital (from 2023)"
                    }
                ]
        },
        {
            "simptoms":
                [
                    {"name": "High temperature", "id": 1, "medId": [1]},
                    {"name": "Sore throat", "id": 2, "medId": [1,9]},
                    {"name": "Headache, Migraine", "id": 3, "medId": [1,7]},
                    {"name": "Joint Pain", "id": 4, "medId": [14,1]},
                    {"name": "Low back pain", "id": 5, "medId": [7,1]},
                    {"name": "Traumatic pain", "id": 6, "medId": [14,1]},
                    {"name": "Facial rash", "id": 7, "medId": [15,16]},
                    {"name": "Cold", "id": 8, "medId": [1]},
                    {"name": "Allergic rash", "id": 9, "medId": [17,16,1]},
                    {"name": "Skin disease", "id": 10, "medId": [16]},
                    {"name": "Pregnancy", "id": 11, "medId": [13]},
                    {"name": "Menstruation", "id": 12, "medId": [13]},
                    {"name": "Sex life", "id": 13, "medId": [12]},
                    {"name": "Show analysis", "id": 14, "medId": [1]},
                    {"name": "Chest Pain", "id": 15, "medId": [5,1]},
                    {"name": "Urinary tract disease", "id": 16, "medId": [12]},
                    {"name": "Nasal congestion", "id": 17, "medId": [1,9]},
                    {"name": "Contraceptive", "id": 18, "medId": [13]},
                    {"name": "Earache", "id": 19, "medId": [9,1]},
                    {"name": "Stress at work", "id": 20, "medId": [18]},
                    {"name": "Gastrointestinal", "id": 21, "medId": [6,1]},
                    {"name": "Low immunity", "id": 22, "medId": [17,1]},
                    {"name": "Hormonal change", "id": 23, "medId": [8]},
                    {"name": "Other symptom", "id": 24, "medId": [1]},
                    {"name": "Arterial pressure", "id": 25, "medId": [5]},
                    {"name": "Diabetes", "id": 26, "medId": [8,1]},
                    {"name": "Weight loss", "id": 27, "medId": [19]},
                    {"name": "Rheumatism", "id": 28, "medId": [20]},
                    {"name": "Vitamin D deficiency", "id": 29, "medId": [1]},
                    {"name": "Dizziness", "id": 30, "medId": [7]},
                ]
        },
        {
            "simptomsChild":
                [
                    {"name": "Sore Throat", "id": 1, "medId": [1,2]},
                    {"name": "High fever", "id": 2, "medId": [1]},
                    {"name": "Child Infection", "id": 3, "medId": [1]},
                    {"name": "Disturbed sleep", "id": 4, "medId": [1,3]},
                    {"name": "Gastrointestinal", "id": 5, "medId": [4,1]},
                    {"name": "Child rash", "id": 6, "medId": [7,1]},
                    {"name": "Show analysis", "id": 7, "medId": [1]},
                    {"name": "Physical development", "id": 8, "medId": [1]},
                    {"name": "Degradation", "id": 9, "medId": [1,8]},
                    {"name": "Another question", "id": 10, "medId": [1]},
                ]
        }
        ,
        {
            "medicalField":
                [
                    {"name": "Therapist", "id": 1},
                    {"name": "Infectionist", "id": 2},
                    {"name": "Pulmanologist", "id": 3},
                    {"name": "Phytiziatr", "id": 4},
                    {"name": "Cardiologist", "id": 5},
                    {"name": "Gastroenterologist", "id": 6},
                    {"name": "Neuropathologist", "id": 7},
                    {"name": "Endocrinologist", "id": 8},
                    {"name": "Lor", "id": 9},
                    {"name": "Algolog", "id": 10},
                    {"name": "General Surgeon", "id": 11},
                    {"name": "Urologist-andrologist", "id": 12},
                    {"name": "Gynecologist", "id": 13},
                    {"name": "Traumatologist-orthopedic", "id": 14},
                    {"name": "Cosmetologist", "id": 15},
                    {"name": "Dermotologist", "id": 16},
                    {"name": "Allergist-immunologist", "id": 17},
                    {"name": "Psychologist", "id": 18},
                    {"name": "Dietitian", "id": 19},
                    {"name": "Rheumatologist", "id": 20},
                ]
        },
        {
            "medicalFieldChild":
                [
                    {"name": "Pediatrician", "id": 1},
                    {"name": "Lor", "id": 2},
                    {"name": "Neuropathologist", "id": 3},
                    {"name": "Gastroenterologist", "id": 4},
                    {"name": "Cardiologist", "id": 5},
                    {"name": "Gastroenterologist", "id": 6},
                    {"name": "Dermotologist", "id": 7},
                    {"name": "Endocrinologist", "id": 8}
                ]
        }
    ];
    const [siteData,setSiteData]= useState(siteDataAz)
    const changeSiteDataByLang=(lang)=>{
        setSiteData(lang)
    }
    // debugger
    useEffect(()=>{
        // debugger
        if(lang === 'AZ'){
            changeSiteDataByLang (siteDataAz)

        }else if(lang === 'RU'){
            changeSiteDataByLang (siteDataRu)
        }else{
            changeSiteDataByLang (siteDataEn)
        }
    },[lang])
    useEffect(()=>{
        // debugger
        setSiteDataByChild()
    },[siteData])

    const selectedLangData = languagesArr.find(el => el.langKey === lang).langValues
    const [navbarByLang, setNavbarByLang] = useState(selectedLangData)
    const changeLanguage = (lang) => {
        setLang(lang)
        setNavbarByLang(changePageLangValues(lang))
    }
    const changePageLangValues = (lang) => {
        return languagesArr.find(el => el.langKey === lang).langValues;
    }

    // const changeChevronPosition = (chevronNumber) => {
    //     if (chevronNumber === 1) {
    //         setIsChevronRight1(!isChevronRight1)
    //         setIsChevronRight2(true)
    //         setIsChevronRight3(true)
    //         setIsChevronRight4(true)
    //         setIsChevronRight5(true)
    //     }
    //     if (chevronNumber === 2) {
    //         setIsChevronRight2(!isChevronRight2)
    //         setIsChevronRight1(true)
    //         setIsChevronRight3(true)
    //         setIsChevronRight4(true)
    //         setIsChevronRight5(true)
    //     }
    //     if (chevronNumber === 3) {
    //         setIsChevronRight3(!isChevronRight3)
    //         setIsChevronRight1(true)
    //         setIsChevronRight2(true)
    //         setIsChevronRight4(true)
    //         setIsChevronRight5(true)
    //     }
    //     if (chevronNumber === 4) {
    //         setIsChevronRight4(!isChevronRight4)
    //         setIsChevronRight1(true)
    //         setIsChevronRight2(true)
    //         setIsChevronRight3(true)
    //         setIsChevronRight5(true)
    //     }
    //     if (chevronNumber === 5) {
    //         setIsChevronRight5(!isChevronRight5)
    //         setIsChevronRight1(true)
    //         setIsChevronRight2(true)
    //         setIsChevronRight3(true)
    //         setIsChevronRight4(true)
    //     }
    // }

    const[ simptomsList,setSimptomsList ]= useState([])

    const [activeSimptomsIdList, setActiveSimptomsIdList] = useState([])
    const [activeSimptomCount, setActiveSimptomsCount ] = useState(0)
    const [medicalFieldList, setMedicalFieldList ] = useState([])
    const [activeFieldIdList, setActiveFieldIdList] = useState([])
    const [isOnlyMedField, setOnlyMedField] = useState(false)
    const [isAllDoctors, setIsAllDoctors] = useState(false)
    const [doctorList, setdDoctorList ] = useState([])

    const checkIsSimptomSelected =(id)=>{
        if(!activeSimptomsIdList.includes(id))
        {
            setActiveSimptomsIdList([...activeSimptomsIdList, id])
        }
        else {
            setActiveSimptomsIdList(activeSimptomsIdList.filter(sId => sId !== id))
        }
    }
    const checkIsMedFieldClicked =(id)=>{

        if(!activeFieldIdList.includes(id))
        {
            setActiveFieldIdList([...activeFieldIdList, id])
        }
        else {
            setActiveFieldIdList(activeFieldIdList.filter(sId => sId !== id))
        }
    }

    const checkIsFieldSelected = () => {
        if (activeSimptomsIdList.length > 0)
            for (let i = 0; i < activeSimptomsIdList.length; i++) {
                let simptMedFieldArr = simptomsList.find(el => el.id === activeSimptomsIdList[i]).medId
                if (activeFieldIdList.length === 0) {
                    setActiveFieldIdList(simptMedFieldArr)
                } else {
                    if(activeSimptomCount > activeSimptomsIdList.length){
                        setActiveFieldIdList(simptMedFieldArr)
                    }else
                        setActiveFieldIdList([...simptMedFieldArr, ...activeFieldIdList.filter(el => !simptMedFieldArr.includes(el))])
                }
            } else {
            setActiveFieldIdList([])
        }
    }
    const setCountMedFields=()=>{
        setActiveSimptomsCount(activeSimptomsIdList.length)
    }
    const onlyMedField=()=>{
        setOnlyMedField(true)
    }
    // const [modalDoctor, setModalDoctor] = useState(null)
    // const setDoc =(doctor)=>{
    //     setModalDoctor(doctor);
    // }
    // const getCurrentDoctorData =(id)=>{
    //     let doctor ={}
    //     if(doctorList.length > 0) doctor = doctorList.find(el=> el.id === id)
    //     return doctor
    // }
    // const openModalDoctor =( el)=>{
    //     console.log(el.target.id)
    //     let doctor = getCurrentDoctorData(parseInt(el.target.id))
    //     setDoc(doctor)
    // }
    const shareWpDoctorModal =(el)=>{
        // debugger
        let doctor = getCurrentDoctorData(parseInt(el))
        let urlEnText = encodeURIComponent('Salam. Həkim '+doctor.fullname + ' haqqında məlumat almaq istəyirəm')
        let sharedDataSample={
            text: doctor.fullname,
            url: 'https://wa.me/994702666511?text='+urlEnText
        }
        window.location.href =  sharedDataSample.url;
    }
    const [showModalSOS, setShowModalSOS] =useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const setSiteDataByChild =()=>{
        // debugger

        if(isChild === true){
            setSimptomsList(siteData.find(el => el.simptomsChild)["simptomsChild"]);
            setMedicalFieldList ( siteData.find(el => el.medicalFieldChild)["medicalFieldChild"]);
            setdDoctorList( siteData.find(el => el.doctorsChild)["doctorsChild"]);
        } else
        {
            setSimptomsList(siteData.find(el => el.simptoms)["simptoms"]);
            setMedicalFieldList ( siteData.find(el => el.medicalField)["medicalField"]);
            setdDoctorList( siteData.find(el => el.doctors)["doctors"]);
        }
    }
    useEffect(() => {
        setSiteDataByChild()
    }, [isChild])

    useEffect(()=>{
        setCountMedFields()
        checkIsFieldSelected()
    },[activeSimptomsIdList])
    useEffect(()=>{
        if(activeFieldIdList.length ===0 && activeSimptomsIdList.length !==0) {
            setActiveSimptomsIdList([])
        }
    },[activeFieldIdList])

    const [modalDoctor, setModalDoctor] = useState(null)
    const spliterFullName = (fullname)=>{
        let name = fullname.split(' ')[0]
        let surname = fullname.split(' ')[1]
        return name +' '+ surname[0]
    }
    const openModalDoctor =( el)=>{
        console.log(el.target.id)
        let doctor = getCurrentDoctorData(parseInt(el.target.id))
        setDoc(doctor)
    }
    const getCurrentDoctorData =(id)=>{
        let doctor ={}
        if(doctorList.length > 0) doctor = doctorList.find(el=> el.id === id)
        return doctor
    }
    const setDoc =(doctor)=>{
        setModalDoctor(doctor);
    }
    useEffect(()=>{
        setModalShow(true)
        console.log(modalDoctor)
    },[modalDoctor])
    const reLoadPage=()=>{
        window.location.reload()
    }
    const [modalShow, setModalShow] = useState(false);
// debugger
    return (
            isSplashLoaded
            ?
                <SplashScreen setSplashLoaded={setSplashLoaded}/>
            :
                <Container fluid className="wrapper">
                    {isPortrait
                        ?  <Mobile
                            activeSimptomsIdList={activeSimptomsIdList}
                            setActiveSimptomsIdList={setActiveSimptomsIdList}
                            activeSimptomCount={activeSimptomCount}
                            setActiveSimptomsCount={setActiveSimptomsCount}
                            setMedicalFieldList={setMedicalFieldList}
                            setdDoctorList={setdDoctorList}
                            isChild={isChild}
                            changeIsChild={changeIsChild}
                            languagesArr={languagesArr}
                            doctorList={doctorList}
                            simptomsList={simptomsList}
                            activeFieldIdList={activeFieldIdList}
                            setActiveFieldIdList={setActiveFieldIdList}
                            medicalFieldList={medicalFieldList}
                            getCurrentDoctorData={getCurrentDoctorData}
                            openModalDoctor={openModalDoctor}
                            shareWpDoctorModal={shareWpDoctorModal}
                            modalDoctor={modalDoctor}
                            setModalDoctor={setModalDoctor}
                            lang={lang}
                            navbarByLang={navbarByLang}
                            setDoc={setDoc}
                            changeLanguage={changeLanguage}
                            setLang={setLang}
                            reLoadPage={reLoadPage}
                            setNavbarByLang={setNavbarByLang}
                            showModalSOS={showModalSOS}
                            setShowModalSOS={setShowModalSOS}

                        />
                        :<Web
                            showModalSOS={showModalSOS}
                            setShowModalSOS={setShowModalSOS}
                            activeSimptomsIdList={activeSimptomsIdList}
                            setActiveSimptomsIdList={setActiveSimptomsIdList}
                            activeSimptomCount={activeSimptomCount}
                            setActiveSimptomsCount={setActiveSimptomsCount}
                            setMedicalFieldList={setMedicalFieldList}
                            setdDoctorList={setdDoctorList}
                            isChild={isChild}
                            changeIsChild={changeIsChild}
                            doctorList={doctorList}
                            simptomsList={simptomsList}
                            activeFieldIdList={activeFieldIdList}
                            setActiveFieldIdList={setActiveFieldIdList}
                            medicalFieldList={medicalFieldList}
                            getCurrentDoctorData={getCurrentDoctorData}
                            openModalDoctor={openModalDoctor}
                            shareWpDoctorModal={shareWpDoctorModal}
                            modalDoctor={modalDoctor}
                            setModalDoctor={setModalDoctor}
                            lang={lang}
                            navbarByLang={navbarByLang}
                            setDoc={setDoc}
                            changeLanguage={changeLanguage}
                            setLang={setLang}
                            setNavbarByLang={setNavbarByLang}
                            reLoadPage={reLoadPage}
                        />
                    }
                </Container>
    );
}

export default App;


{/*<Accordion defaultActiveKey="0">*/
}
{/*    <Accordion.Item  eventKey="0">*/
}
{/*        <Accordion.Header>{ourServicesHead1}</Accordion.Header>*/
}
{/*        <Accordion.Body>*/
}
{/*            {loremBig}*/
}
{/*        </Accordion.Body>*/
}
{/*    </Accordion.Item>*/
}
{/*    <Accordion.Item eventKey="1">*/
}
{/*        <Accordion.Header>{ourServicesHead2}</Accordion.Header>*/
}
{/*        <Accordion.Body>*/
}
{/*            {loremBig}*/
}
{/*        </Accordion.Body>*/
}
{/*    </Accordion.Item>*/
}
{/*    <Accordion.Item eventKey="1">*/
}
{/*        <Accordion.Header>{ourServicesHead3}</Accordion.Header>*/
}
{/*        <Accordion.Body>*/
}
{/*            {loremBig}*/
}
{/*        </Accordion.Body>*/
}
{/*    </Accordion.Item>*/
}
{/*    <Accordion.Item eventKey="1">*/
}
{/*        <Accordion.Header>{ourServicesHead4}</Accordion.Header>*/
}
{/*        <Accordion.Body>*/
}
{/*            {loremBig}*/
}
{/*        </Accordion.Body>*/
}
{/*    </Accordion.Item>*/
}
{/*    <Accordion.Item eventKey="1">*/
}
{/*        <Accordion.Header>{ourServicesHead5}</Accordion.Header>*/
}
{/*        <Accordion.Body>*/
}
{/*            {loremBig}*/
}
{/*        </Accordion.Body>*/
}
{/*    </Accordion.Item>*/
}
{/*</Accordion>*/
}

// <div className="App">
//   <header className="App-header">
//     <img src={logo} className="App-logo" alt="logo" />
//     <p>
//       Edit <code>src/App.js</code> and save to reload.
//     </p>
//     <a
//       className="App-link"
//       href="https://reactjs.org"
//       target="_blank"
//       rel="noopener noreferrer"
//     >
//       Learn React
//     </a>
//   </header>
// </div>}

{/*{*/
}
{/*    isDesktopOrLaptop &&*/
}
{/*    <>*/
}
{/*    <div className="simptomsHeader" >*/
}
{/*        <p className="headerTextSize">*/
}
{/*            Simptomlara görə sırala*/
}
{/*        </p>*/
}
{/*    </div>*/
}
{/*    <div className="simptomsButtons d-flex flex-row justify-content-between">*/
}
{/*    <Col className="d-flex flex-row justify-content-center">*/
}
{/*    <Button   className='customDangerColor customButtonSize rounded-6' variant='danger'>*/
}
{/*    Baş ağrısı*/
}
{/*    </Button>*/
}
{/*    </Col>*/
}
{/*    <Col className="d-flex flex-row justify-content-center">*/
}
{/*    <Button  className="customButtonSize rounded-6"  variant='light'>*/
}
{/*    xxxx xxxx*/
}
{/*    </Button>*/
}
{/*    </Col>*/
}
{/*    <Col className="d-flex flex-row justify-content-center">*/
}
{/*    <Button  className="customButtonSize rounded-6" variant='light'>*/
}
{/*    xxxx xxxx*/
}
{/*    </Button>*/
}
{/*    </Col>*/
}
{/*    <Col className="d-flex flex-row justify-content-center">*/
}
{/*    <Button  className="customButtonSize rounded-6" variant='light'>*/
}
{/*    xxxx xxxx*/
}
{/*    </Button>*/
}
{/*    </Col>*/
}
{/*    <Col className="d-flex flex-row justify-content-center">*/
}
{/*    <Button  className="customButtonSize rounded-6" variant='light'>*/
}
{/*    xxxx xxxx*/
}
{/*    </Button>*/
}
{/*    </Col>*/
}
{/*    <Col className="d-flex flex-row justify-content-center">*/
}
{/*    <Button  className="customButtonSize rounded-6"  variant='light'>*/
}
{/*    xxxx xxxx*/
}
{/*    </Button>*/
}
{/*    </Col>*/
}
{/*    <Col className="d-flex flex-row justify-content-center">*/
}
{/*    <Button  className="customButtonSize rounded-6" variant='light'>*/
}
{/*    xxxx xxxx*/
}
{/*    </Button>*/
}
{/*    </Col>*/
}
{/*    <Col className="d-flex flex-row justify-content-center">*/
}
{/*    <Button  className="customButtonSize rounded-6" variant='light'>*/
}
{/*    xxxx xxxx*/
}
{/*    </Button>*/
}
{/*    </Col>*/
}
{/*    </div>*/
}
{/*    </>*/
}
{/*}*/
}
{/*{*/
}
{/*    isTabletOrMobile &&*/
}
{/*    <>*/
}
{/*    </>*/
}
{/*}*/
}

