import Image from "react-bootstrap/Image";
import imgWp from "../assets/imgWhatsupLogo.png";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import appLogo from "../assets/appLogo.png";
import {
    MDBAccordion, MDBAccordionItem,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBIcon
} from "mdb-react-ui-kit";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import imgSlider from "../assets/imgSlider1.png";
import Card from "react-bootstrap/Card";
import imgProfile from "../assets/imgProfile.png";
import imgDownloadFileBtn from "../assets/imgDownloadFileBtn.png";
import imgSocar from "../assets/imgSocarLogo.png";
import imgITS from "../assets/imgITSLogo.png";
import imgAAC from "../assets/imgAACSSLogo.png";
import imgMega from "../assets/imgMegaLogo.png";
import imgAtewgah from "../assets/imgAteshgahLogo.png";
import imgPasha from "../assets/imgPashaLogo.png";
import imgMedicSchool from "../assets/imgMedicSchoolLogo.png";
import imgSosialsALL from "../assets/imgSosialsALL.png";
import imgDownloadAppMobile from "../assets/mobile/imgDownloadAppMobile.png";
import imgPlayStore from "../assets/imgPlayStore.png";
import {useMediaQuery} from "react-responsive";
import React, {useEffect, useRef, useState} from "react";
import style from "./web.module.css";
import centeredModalSOS from '../components/CenteredModalSOS/CenteredModalSOS'
import CenteredModal from "../components/CenteredModal/CenteredModal";
import CenteredModalSOS from "../components/CenteredModalSOS/CenteredModalSOS";

const Web =({languagesArr,doctorList,simptomsList,medicalFieldList,activeFieldIdList,setActiveFieldIdList,changeIsChild,isChild,
                activeSimptomsIdList,
                setActiveSimptomsIdList,
                activeSimptomCount,
                setActiveSimptomsCount,
                setMedicalFieldList,
                modalDoctor,
                setModalDoctor,
                setDoc,
                shareWpDoctorModal,
                changeLanguage,
                setLang,
                lang,
                navbarByLang,
                showModalSOS,
                setShowModalSOS,
                setNavbarByLang,
                reLoadPage,
                setdDoctorList})=> {
    const isBigScreen = useMediaQuery({query: '(min-width: 1824px)'})
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1223px)'})
    const is670px = useMediaQuery({query: '(min-width: 670px)'})
    const isPortraitMinSize = useMediaQuery({query: '(max-width: 575.98px)'})
    const isPortrait = useMediaQuery({query: '(orientation: portrait)'})
    const isRetina = useMediaQuery({query: '(min-resolution: 2dppx)'})

    const [isChevronRight1, setIsChevronRight1] = useState(true)
    const [isChevronRight2, setIsChevronRight2] = useState(true)
    const [isChevronRight3, setIsChevronRight3] = useState(true)
    const [isChevronRight4, setIsChevronRight4] = useState(true)
    const [isChevronRight5, setIsChevronRight5] = useState(true)

    // const [lang, setLang] = useState('AZ')

    // let selectedLangData = languagesArr.find(el => el.langKey === lang).langValues

    // const [navbarByLang, setNavbarByLang] = useState(selectedLangData)
    // const changeLanguage = (lang) => {
    //     setLang(lang)
    //     setNavbarByLang(changePageLangValues(lang))
    // }
    // const changePageLangValues = (lang) => {
    //     return languagesArr.find(el => el.langKey === lang).langValues;
    // }
    const changeChevronPosition = (chevronNumber) => {
        if (chevronNumber === 1) {
            setIsChevronRight1(!isChevronRight1)
            setIsChevronRight2(true)
            setIsChevronRight3(true)
            setIsChevronRight4(true)
            setIsChevronRight5(true)
        }
        if (chevronNumber === 2) {
            setIsChevronRight2(!isChevronRight2)
            setIsChevronRight1(true)
            setIsChevronRight3(true)
            setIsChevronRight4(true)
            setIsChevronRight5(true)
        }
        if (chevronNumber === 3) {
            setIsChevronRight3(!isChevronRight3)
            setIsChevronRight1(true)
            setIsChevronRight2(true)
            setIsChevronRight4(true)
            setIsChevronRight5(true)
        }
        if (chevronNumber === 4) {
            setIsChevronRight4(!isChevronRight4)
            setIsChevronRight1(true)
            setIsChevronRight2(true)
            setIsChevronRight3(true)
            setIsChevronRight5(true)
        }
        if (chevronNumber === 5) {
            setIsChevronRight5(!isChevronRight5)
            setIsChevronRight1(true)
            setIsChevronRight2(true)
            setIsChevronRight3(true)
            setIsChevronRight4(true)
        }
    }

    // const[ simptomsList,setSimptomsList ]= useState([])

    // const [activeSimptomsIdList, setActiveSimptomsIdList] = useState([])
    // const [activeSimptomCount, setActiveSimptomsCount ] = useState(0)
    // const [medicalFieldList, setMedicalFieldList ] = useState([])
    // const [activeFieldIdList, setActiveFieldIdList] = useState([])
    const [isOnlyMedField, setOnlyMedField] = useState(false)
    const [isAllDoctors, setIsAllDoctors] = useState(false)
    // const [doctorList, setdDoctorList ] = useState([])

    const checkIsSimptomSelected =(id)=>{
        if(!activeSimptomsIdList.includes(id))
        {
            setActiveSimptomsIdList([...activeSimptomsIdList, id])
        }
        else {
            setActiveSimptomsIdList(activeSimptomsIdList.filter(sId => sId !== id))
        }
    }
    const checkIsMedFieldClicked =(id)=>{

        if(!activeFieldIdList.includes(id))
        {
            setActiveFieldIdList([...activeFieldIdList, id])
        }
        else {
            setActiveFieldIdList(activeFieldIdList.filter(sId => sId !== id))
        }
    }

    const getCurrentDoctorData =(id)=>{
        let doctor ={}
        if(doctorList.length > 0) doctor = doctorList.find(el=> el.id === id)
        return doctor
    }
    const openModalDoctor =( el)=>{
        console.log(el.target.id)
        let doctor = getCurrentDoctorData(parseInt(el.target.id))
        setDoc(doctor)
    }
    // const shareWpDoctorModal =(el)=>{
    //     let doctor = getCurrentDoctorData(parseInt(el))
    //     let urlEnText = encodeURIComponent('Salam. Həkim '+doctor.fullname + ' haqqında məlumat almaq istəyirəm')
    //     let sharedDataSample={
    //         text: doctor.fullname,
    //         url: 'https://wa.me/994702666511?text='+urlEnText
    //     }
    //     window.location.href =  sharedDataSample.url;
    // }
    const checkIsFieldSelected = () => {
        if (activeSimptomsIdList.length > 0)
            for (let i = 0; i < activeSimptomsIdList.length; i++) {
                let simptMedFieldArr = simptomsList.find(el => el.id === activeSimptomsIdList[i]).medId
                if (activeFieldIdList.length === 0) {
                    setActiveFieldIdList(simptMedFieldArr)
                } else {
                    if(activeSimptomCount > activeSimptomsIdList.length){
                        setActiveFieldIdList(simptMedFieldArr)
                    }else
                        setActiveFieldIdList([...simptMedFieldArr, ...activeFieldIdList.filter(el => !simptMedFieldArr.includes(el))])
                }
            } else {
            setActiveFieldIdList([])
        }
    }
    const setCountMedFields=()=>{
        setActiveSimptomsCount(activeSimptomsIdList.length)
    }
    const onlyMedField=()=>{
        setOnlyMedField(true)
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        // setSimptomsList(siteData.find(el => el.simptoms)["simptoms"]);
        // setMedicalFieldList ( siteData.find(el => el.medicalField)["medicalField"]);
        // setdDoctorList( siteData.find(el => el.doctors)["doctors"]);

        // debugger
        // console.log("medicalFieldList")
        // console.log(medicalFieldList)
    }, [])
    useEffect(()=>{
        setCountMedFields()
        checkIsFieldSelected()
        console.log(activeSimptomsIdList)
    },[activeSimptomsIdList])
    useEffect(()=>{
        if(activeFieldIdList.length ===0 && activeSimptomsIdList.length !==0) {
            setActiveSimptomsIdList([])
        }
        console.log(activeFieldIdList)
    },[activeFieldIdList])
    // useEffect(()=>{
    //
    //     console.log('isAllDoctors - '+isAllDoctors)
    // },[isAllDoctors])

    const allSimptoms = simptomsList.length > 0 && simptomsList.map(el =>
    <Button key={el.id}
        onClick={()=> {
            checkIsSimptomSelected(el.id)
        }}
        className={`${activeSimptomsIdList.includes(el.id) ? style.btnRed : style.btnGrey } 
        ${style.customButtonSize} 
        text-nowrap shadow-none rounded-6`}
        variant='light'
    >
        {el.name}
    </Button>
    )

    const allMedicalActiveField = medicalFieldList.length > 0
        && medicalFieldList.map(el => {
            if (activeFieldIdList.includes(el.id))
                return (
            <Button key={el.id}
                onClick={
                    () => {
                        checkIsMedFieldClicked(el.id)
                        onlyMedField()
                    }
                }
                className={`${style.customButtonSize} ${style.btnRed} rounded-6 text-nowrap shadow-none`} variant='light'>
                {el.name}
            </Button>
                )
        })
    const allMedicalDeactiveField = medicalFieldList.length > 0
        && medicalFieldList.map(el => {
            if (!activeFieldIdList.includes(el.id))
                return (

            <Button key={el.id}
                    onClick={
                        () => {
                            checkIsMedFieldClicked(el.id)
                            onlyMedField()
                        }
                    }
                    className={`${style.customButtonSize} ${style.btnGrey} rounded-6 text-nowrap shadow-none`} variant='light'>
                {el.name}
            </Button>)
        })
    // const [modalDoctor, setModalDoctor] = useState(null)
    const spliterFullName = (fullname)=>{
        let name = fullname.split(' ')[0]
        let surname = fullname.split(' ')[1]
        return name +' '+ surname[0]
    }

//         ${activeFieldIdList.length !==0 && (!activeFieldIdList.includes(el.medicalField) && ' d-none ')}
// const [showModalSOS, setShowModalSOS] =useState(false)
    const allDoctorsActive = doctorList.length > 0 && doctorList.map(el =>
    <Col xs={12} md={6} xl={4}
         key={el.id}
         id={parseInt(el.id)}
         className={`d-flex flex-row justify-content-center p-1 g-0 m-0 mb-2
           ${activeFieldIdList.length !== 0 ? (!activeFieldIdList.includes(el.medicalField) ? ' d-none ' : '') : ''}}
         `}

    >
        <Card className="doctorsCards shadow-none "
              key={el.id}
              id={parseInt(el.id)}>
            <Card.Body className="customPaddingImg mb-0">
                <Container className="">
                    <Row>
                        <Col xs={4} className="profileCol ">
                            <Image className="profileCol" style={{borderRadius:"10px"}} src={el.image !== null ? el.image : imgProfile}/>
                        </Col>
                        <Col xs={8} className=" gx-5">
                            <Card.Title className="m-0">
                                Dr. {el.fullname}
                            </Card.Title>
                            <p style={{fontSize: "0.764rem"}} className="g-1 m-0">
                                {medicalFieldList.length >0 && medicalFieldList.find(elm => elm.id === parseInt(el.medicalField)).name}
                            </p>
                            <p>
                                <svg width="41" height="18" viewBox="0 0 41 18" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect width="41" height="18" rx="2.22642" fill="#F3E8E8"/>
                                    <path
                                        d="M12.7059 10.7567C12.5656 10.8927 12.5011 11.0893 12.5331 11.2822L13.0146 13.9472C13.0552 14.173 12.9599 14.4016 12.7709 14.5322C12.5856 14.6676 12.3391 14.6838 12.1371 14.5755L9.73806 13.3242C9.65465 13.2798 9.56202 13.256 9.46723 13.2533H9.32044C9.26952 13.2609 9.21969 13.2771 9.17419 13.302L6.7746 14.5592C6.65598 14.6188 6.52165 14.64 6.39002 14.6188C6.06935 14.5582 5.8554 14.2527 5.90794 13.9304L6.39002 11.2654C6.42198 11.0709 6.35752 10.8732 6.21723 10.7351L4.26127 8.83925C4.09769 8.68054 4.04081 8.44221 4.11556 8.22716C4.18815 8.01266 4.3734 7.85612 4.5971 7.82091L7.28919 7.43037C7.49394 7.40925 7.67377 7.28466 7.76585 7.1005L8.9521 4.66841C8.98027 4.61425 9.01656 4.56441 9.06044 4.52216L9.10919 4.48425C9.13465 4.45608 9.1639 4.43279 9.1964 4.41383L9.25544 4.39216L9.34752 4.35425H9.57556C9.77923 4.37537 9.95852 4.49725 10.0522 4.67925L11.2542 7.1005C11.3409 7.27762 11.5093 7.40058 11.7038 7.43037L14.3959 7.82091C14.6234 7.85341 14.8135 8.0105 14.8888 8.22716C14.9597 8.44437 14.8985 8.68271 14.7317 8.83925L12.7059 10.7567Z"
                                        fill="#C13130"/>
                                    <path
                                        d="M20.6562 12.2955V11.2301L24.4276 5.27273H25.267V6.84091H24.7344L22.0369 11.1108V11.179H27.206V12.2955H20.6562ZM24.794 14V11.9716L24.8026 11.4858V5.27273H26.0511V14H24.794ZM30.0376 12.8068L29.9737 13.2713C29.9311 13.6122 29.8587 13.9673 29.7564 14.3366C29.657 14.7088 29.5533 15.054 29.4453 15.3722C29.3402 15.6903 29.2536 15.9432 29.1854 16.1307H28.282C28.3189 15.9545 28.37 15.7159 28.4354 15.4148C28.5007 15.1165 28.5646 14.7827 28.6271 14.4134C28.6896 14.044 28.7365 13.6676 28.7678 13.2841L28.8104 12.8068H30.0376ZM31.8913 14L35.701 6.46591V6.40199H31.2947V5.27273H37.0646V6.44034L33.2678 14H31.8913Z"
                                        fill="#C13130"/>
                                </svg>
                            </p>
                            <p style={{fontSize: "0.764rem"}}>
                                {el.practicYear} il təcrübə
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Card.Text style={{maxHeight: "100px", fontSize: "0.764rem", overflow: "auto"}}>
                            {el.description}
                        </Card.Text>
                    </Row>
                    <Row>
                        <Col className="d-flex flex-row justify-content-end mt-3 mb-0">
                            <Button id={el.id}
                                    onClick={(el)=>{
                                        // debugger
                                        // console.log(el.target.id)
                                        // let doctor = getCurrentDoctorData(parseInt(el.target.id))
                                        shareWpDoctorModal(parseInt(el.target.id))
                                    }}
                                    className="customDangerColor mb-0" variant="danger"> {navbarByLang.body.doctors.getInformation}</Button>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    </Col>
    )
    const threeDoctorsForAboutUs = doctorList.length > 0 && doctorList.slice(0, 4).map(el =>
    <Col key={el.id} xl={4} md={6} xs={12} className={`${isDesktopOrLaptop && 'aboutUsCardSizeXl'} aboutUsCards h-auto`}

    >
        <Card key={el.id} className="g-0 m-0 p-0 h-auto  shadow-0">
            <Card.Body className="g-0 m-0 pt-2"
                       style={{paddingLeft: "20px", width: "inherit", height: "131px"}}>
                <Row style={{width: "inherit", height: "124px"}}>
                    <Col xs={4} className="profileCol g-0">
                        <Image style={{
                            height: "inherit", width: "inherit" ,borderRadius:"10px"
                        }}  className="" src={el.image !== null ? el.image : imgProfile}/>
                    </Col>
                    <Col style={{maxHeight: "124px", overflow: "auto"}} className="ms-3 g-0">
                        <Card.Title className="m-0">
                            Dr. {el.fullname}
                        </Card.Title>
                        <p style={{fontSize: "0.764rem"}} className="g-1 m-0">
                            {medicalFieldList.length >0 &&  medicalFieldList.find(elm => elm.id === parseInt(el.medicalField)).name}
                        </p>
                        <Card.Text style={{
                            fontSize: "0.764rem",
                            fontWeight: "400",
                            color: "#9F9F9F",
                            maxHeight: "80px"
                        }}>
                            {el.description}
                        </Card.Text>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </Col>
)
    const allDoctorsNotActive = doctorList.length > 0 && doctorList.map(el =>
        <Col key={el.id}
             id={parseInt(el.id)}
             onClick={
                 (el) => {
                     // openModalDoctor( el)
                 }}
             xs={4}
             style={{
                 transition: 'all  0.2s ease-out', cursor:'pointer'
             }}
             className={`d-flex flex-row flex-column nowrap justify-content-start p-1 g-0 me-1
                  ${activeFieldIdList.length !==0 ? (!activeFieldIdList.includes(el.medicalField) ? ' d-none ' :'') :''}`}
        >
            <Card key={el.id}  id={parseInt(el.id)}
                  onClick={
                      (el) => {
                          // openModalDoctor( el)
                      }}
                  className={`${style.doctorsCards} shadow-none`}>
                <Card.Body id={parseInt(el.id)}  className={`${style.customPaddingImg} mb-0`}>
                    <Container   id={parseInt(el.id)}  className="">
                        <Row   id={parseInt(el.id)}  className=''>
                            <Col id={parseInt(el.id)}  xs={5} className='  w-auto'>
                                <Col id={parseInt(el.id)}  xs={12} className={` d-flex flex-row justify-content-center`}>
                                    <Image id={parseInt(el.id)} className={`${style.profileCol}`}
                                           src={el.image !== null ? el.image : imgProfile} style={{borderRadius:"10px"}}/>
                                </Col>
                                <Col id={parseInt(el.id)}  xs={12} className="">
                                    <Card.Title id={parseInt(el.id)}  className="m-0 p-0 g-0" style={{minHeight: "20px", height:'auto'}}>
                                        <p id={parseInt(el.id)}  style={{
                                            fontWeight: '700',
                                            fontSize: '12px',
                                            // margin: 10px 0px;
                                            whiteSpace: 'nowrap',
                                            height: '18px',
                                            lineHeight: '18px',
                                            marginBottom: '0px',
                                            fontFamily: `'Poppins', sans-serif`
                                        }}>{el.id} Dr. {spliterFullName(el.fullname)}</p>
                                    </Card.Title>
                                    <Col  id={parseInt(el.id)} xs={12} className="m-0 p-0 g-0">
                                        <p
                                            // style={{fontSize: "0.764rem"
                                            style={{
                                                fontWeight: '500',
                                                fontSize: '9px',
                                                // margin: 10px 0px;
                                                lineHeight: '13.5px',
                                                fontFamily: `'Poppins', sans-serif`,
                                                color: "#ADADAD"
                                            }} className="g-1 m-0 mb-1">
                                            {medicalFieldList.find(elm => elm.id === parseInt(el.medicalField)).name}
                                        </p>
                                    </Col>
                                    <Row id={parseInt(el.id)}  className='' style={{height: '20px', overflow: "hidden"}}>
                                        <Col id={parseInt(el.id)}  xs={6} className='' style={{height: '20px'}}>
                                            <p  id={parseInt(el.id)} style={{marginBottom: '0px'}}>
                                                <svg width="29" height="14" viewBox="0 0 41 18" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="41" height="18" rx="2.22642"
                                                          fill="#F3E8E8"/>
                                                    <path
                                                        d="M12.7059 10.7567C12.5656 10.8927 12.5011 11.0893 12.5331 11.2822L13.0146 13.9472C13.0552 14.173 12.9599 14.4016 12.7709 14.5322C12.5856 14.6676 12.3391 14.6838 12.1371 14.5755L9.73806 13.3242C9.65465 13.2798 9.56202 13.256 9.46723 13.2533H9.32044C9.26952 13.2609 9.21969 13.2771 9.17419 13.302L6.7746 14.5592C6.65598 14.6188 6.52165 14.64 6.39002 14.6188C6.06935 14.5582 5.8554 14.2527 5.90794 13.9304L6.39002 11.2654C6.42198 11.0709 6.35752 10.8732 6.21723 10.7351L4.26127 8.83925C4.09769 8.68054 4.04081 8.44221 4.11556 8.22716C4.18815 8.01266 4.3734 7.85612 4.5971 7.82091L7.28919 7.43037C7.49394 7.40925 7.67377 7.28466 7.76585 7.1005L8.9521 4.66841C8.98027 4.61425 9.01656 4.56441 9.06044 4.52216L9.10919 4.48425C9.13465 4.45608 9.1639 4.43279 9.1964 4.41383L9.25544 4.39216L9.34752 4.35425H9.57556C9.77923 4.37537 9.95852 4.49725 10.0522 4.67925L11.2542 7.1005C11.3409 7.27762 11.5093 7.40058 11.7038 7.43037L14.3959 7.82091C14.6234 7.85341 14.8135 8.0105 14.8888 8.22716C14.9597 8.44437 14.8985 8.68271 14.7317 8.83925L12.7059 10.7567Z"
                                                        fill="#C13130"/>
                                                    <path
                                                        d="M20.6562 12.2955V11.2301L24.4276 5.27273H25.267V6.84091H24.7344L22.0369 11.1108V11.179H27.206V12.2955H20.6562ZM24.794 14V11.9716L24.8026 11.4858V5.27273H26.0511V14H24.794ZM30.0376 12.8068L29.9737 13.2713C29.9311 13.6122 29.8587 13.9673 29.7564 14.3366C29.657 14.7088 29.5533 15.054 29.4453 15.3722C29.3402 15.6903 29.2536 15.9432 29.1854 16.1307H28.282C28.3189 15.9545 28.37 15.7159 28.4354 15.4148C28.5007 15.1165 28.5646 14.7827 28.6271 14.4134C28.6896 14.044 28.7365 13.6676 28.7678 13.2841L28.8104 12.8068H30.0376ZM31.8913 14L35.701 6.46591V6.40199H31.2947V5.27273H37.0646V6.44034L33.2678 14H31.8913Z"
                                                        fill="#C13130"/>
                                                </svg>
                                            </p>
                                        </Col>
                                        <Col id={parseInt(el.id)}  xs={6} style={{height: '20px'}}
                                             className='gx-3  d-flex flex-row justify-content-start align-items-end'>
                                            <p id={parseInt(el.id)}  style={{
                                                fontWeight: '500',
                                                fontSize: '8px',
                                                // margin: 10px 0px;
                                                lineHeight: '9.68px',
                                                whiteSpace: 'nowrap',
                                                fontFamily: `'Poppins', sans-serif`,
                                                color: "#ADADAD",
                                                marginBottom: "0px"
                                            }}>
                                                {el.practicYear} il təcrübə
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Card.Text style={{maxHeight: "100px", fontSize: "0.764rem",overflow: "auto"}}>*/}
                        {/*        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sit amet*/}
                        {/*        diam venenatis, tincidunt massa nec, aliquet lacus. Donec non massa*/}
                        {/*        turpis. Nullam enim nisl, ornare sed ornare eu, fermentum sed enim.*/}
                        {/*        Vestibulum ante ipsum primis in faucibus orci luctus et*/}
                        {/*        ultrices posuere cubilia curae; Donec eu sapien at tortor tincidunt*/}
                        {/*        porttitor eget in justo.*/}
                        {/*    </Card.Text>*/}
                        {/*</Row>*/}
                        {/*<Row>*/}
                        {/*    <Col className="d-flex flex-row justify-content-end mt-3 mb-0">*/}
                        {/*        <Button className={`${style.customDangerColor} mb-0`} variant="danger">Həkimə*/}
                        {/*            yazıl</Button>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </Container>
                </Card.Body>
            </Card>
        </Col>)
    const sliderWpText='Sağlıkçının xidmətləri barədə məlumat almaq istəyirəm';
    let loremBig = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    // const [modalShow, setModalShow] = useState(false);
    return(
        <>
            {showModalSOS !== null &&
            <CenteredModalSOS
                // shareWpDoctorModal={shareWpDoctorModal}
                // doctor={modalDoctor}
                // medfield={medicalFieldList}
                navbarByLang={navbarByLang}
                show={showModalSOS}
                // setDoc={setDoc}
                onHide={() => setShowModalSOS(false)}/>
            }
            <div className={`whatsupIcon`}>
                <Image className={`whatsupIcon`}
                       src={imgWp}
                       style={{cursor:'pointer'}}
                       onClick={()=>{
                           window.location.href= 'https://wa.me/994702666510?text='+ encodeURIComponent(sliderWpText);
                       }}
                />

            </div>
            {/*  f*/
            }
            <Container fluid
                       className={ `d-flex flex-row justify-content-center px-5
                           `}
                       style={{minHeight: `100px`,width:'auto', maxWidth:'1440px', background: `#FFFFFF`}}
            >
                <Container
                    fluid='false'
                    className={` g-0 pt-4 d-flex flex-row  justify-content-between' 
                           `}
                    style={{width: "1270px", maxWidth: "auto"}}
                >
                    <Col xxl={6} xl={6} md={6} className='d-flex flex-row justify-content-start '>
                        <Col md={5}  sm={10} className=" col-4 pe-3"  >
                            <Image onClick={reLoadPage}
                                   role='button'
                                   className={`${style.navbarLogo}`} src={appLogo}/>
                        </Col>
                        <Col md={7} sm= {2} style={{height: "50px", width: isTabletOrMobile && "45px"}}
                             className=" col-8 g-0 ms-2">
                            <MDBDropdown group
                                         style={{border: '1px solid #CDCDCD', borderRadius: '10px', height: "50px"}}
                                         className='shadow-0 g-0 m-0 p-0 '>
                                <MDBDropdownToggle color='light'>{lang}</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem onClick={(e) => {
                                        changeLanguage(e.target.innerText)
                                    }} id="1" name="AZ" className="" link>AZ</MDBDropdownItem>
                                    <MDBDropdownItem onClick={(e) => {
                                        changeLanguage(e.target.innerText)
                                    }} id="2" name="AZ" link>RU</MDBDropdownItem>
                                    <MDBDropdownItem onClick={(e) => {
                                        changeLanguage(e.target.innerText)
                                    }} id="3" name="AZ" link>EN</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </Col>
                    </Col>

                    <Col xxl={6} xl={6} md={6} className={` d-flex justify-content-end ms-2 pe-0`}>

                        <Col className="d-flex flex-row justify-content-center">
                            <a href={"#doctors"}
                               className={`btn btn-danger text-capitalize ${style.customHeigtButton50} text-nowrap pt-3 px-4 me-4
                                   ${style.customDangerColor} rounded-6`}
                            >
                                {navbarByLang.navbarMenu.doctors}
                            </a>
                        </Col>
                        <Col className="d-flex flex-row justify-content-center">
                            <a href={"#ourServices"}
                               className={`text-capitalize btn btn-light ${style.customHeigtButton50} text-nowrap pt-3 px-4 me-4 rounded-6`}
                            >
                                {navbarByLang.navbarMenu.ourServices}
                            </a>
                        </Col>
                        <Col className="d-flex flex-row justify-content-center">
                            <a href={"#aboutUs"}
                               className={`btn btn-light  text-capitalize  ${style.customHeigtButton50} text-nowrap pt-3 px-4 me-4 rounded-6 `}
                            >
                                {navbarByLang.navbarMenu.abouUs}
                            </a>
                        </Col>
                        <Col className="d-flex flex-row justify-content-center">
                            <a href={"#contactUs"}
                               className={`btn btn-light  text-capitalize  ${style.customHeigtButton50} text-nowrap pt-3 px-4 me-4 rounded-6 `}
                            >
                                {navbarByLang.navbarMenu.contact}{``}
                            </a>
                        </Col>
                        <Col className="d-flex flex-row justify-content-end m-0">
                            <Button onClick={()=>{
                                setShowModalSOS(true)
                            }} className={`${style.buttonSos} rounded-6`} variant="light">
                                <MDBIcon style={{fontSize: "1.5em"}} fas icon="phone"/>
                                <span style={{fontSize: "1.5em", marginLeft:'5px'}}>SOS</span>
                            </Button>
                        </Col>
                    </Col>
                </Container>
                {
                    !is670px &&
                    <Container fluid className="g-1 pt-0 mt-0 mx-0 px-0 mb-3">
                        <Col xs={12} className={`${isDesktopOrLaptop && 'navbarSearch'}`}
                             style={isTabletOrMobile ? {minwidth: "200px", width: "auto", height: "50px"} : {}}>
                        </Col>
                    </Container>
                }

            </Container>

            {/*slider*/
            }
            <Container fluid
                       className={`${isDesktopOrLaptop && 'imageSlider'} d-flex flex-row justify-content-center gx-4`}>
                {
                    isDesktopOrLaptop &&
                    <Image className="imageSliderFluid " src={imgSlider}/>
                }
                {
                    isTabletOrMobile &&
                    <>
                        <Image className="d-flex flex-wrap justify-content-center"
                               style={{
                                   marginLeft: "20px",
                                   marginRight: "20px",
                                   maxWidth: "100%",
                                   minHeight: "250px",
                                   height: "parent"
                               }}
                               src={imgSlider}/>
                    </>
                }
            </Container>
            {/*simptoms*/
            }
            <Container className={`g-2 ${style.simptoms}`}>
                <Container className={`${style.simptomsHeader} d-flex flex-row`}>
                    <Col xs={4}>
                        <p className={`${style.headerTextSize}`}>

                            {navbarByLang.body.simptoms.header}
                            {/*Simptomlara görə sırala*/}
                        </p>
                    </Col>
                    <Col className='d-flex flex-row align-items-center justify-content-start pe-0 col'>
                            <span className='me-2' style={{cursor:'pointer'}}>
                                <button
                                    // style={isChild ?isNotSelectedStyle : isSelectedStyle}
                                    style={{height:'32px', padding:'8px 15px', fontSize:"12px"}}
                                    onClick={()=>{
                                        changeIsChild(false)
                                    }}
                                    className={`rounded-3 text-nowrap border-0 shadow-none 
                                            ${style.customButtonSimptom}
                                            ${isChild ?style.btnGrey : style.btnRed }`
                                    }>
                               <span  className={style.fontSimptomButtons}>{navbarByLang.body.simptoms.adult}</span>
                            </button>
                            </span>
                        {/*</Col>*/}
                        {/*<Col className='d-flex flex-row align-items-center  justify-content-start col-3 ps-0 me-0'>*/}
                        <span className='' style={{cursor:'pointer'}}>
                            <button
                                // style={isChild ?isSelectedStyle :isNotSelectedStyle}
                                style={{height:'32px', padding:'8px 15px', fontSize:"12px"}}

                                onClick={()=>{
                                    changeIsChild(true)
                                }}
                                className={`border-0 rounded-3 text-nowrap shadow-none 
                                    ${style.customButtonSimptom} 
                                    ${isChild ? style.btnRed :  style.btnGrey}`}>
                               <span  className={style.fontSimptomButtons}>{navbarByLang.body.simptoms.child}</span>
                            </button>
                            </span>
                    </Col>
                </Container>
                <Container className={`${style.simptomsButtons}`}>
                    <Button className={`rounded-6 shadow-none
                            ${style.customButtonSize} 
                            ${activeSimptomsIdList.length > 0 ? style.btnGrey : style.btnRed}`}
                            onClick={()=>{
                                setActiveSimptomsIdList([])
                                setActiveFieldIdList([])
                            }}
                            variant='danger'>
                        {navbarByLang.body.simptoms.allSimptoms}

                    </Button>
                    {allSimptoms}
                </Container>
            </Container>
            {/*doctors*/
            }
            <Container id="doctors" className={`g-2 ${style.doctors} mb-2`}>
                <Container className={`${style.doctorsHeader}  d-flex flex-row`}>
                    <p className="headerTextSize">
                        {navbarByLang.body.doctors.header}
                        {/*Həkimlərimiz*/}
                    </p>
                </Container>
                <Container className={` ${style.doctorsButtons} d-flex flex-row align-items-start`}>
                    {/*<Button className='customDangerColor customButtonSize rounded-6' variant='danger'>*/}
                    {/*    Baş ağrısı*/}
                    {/*</Button>*/}
                    {allMedicalActiveField}
                    {allMedicalDeactiveField}
                </Container>
                <Container
                    className="g-2 pt-4 d-flex flex-row flex-wrap justify-content-start align-content-start m-0">
                    {
                      allDoctorsActive
                    }
                </Container>

            </Container>
            {/*ourServices*/}
            <div style={{background: "#F7F8F9"}}>
                <Container id='ourServices' className=" ourServices p-0">
                    <Row className="g-0 m-0 ps-4">
                        <p className="headerTextSize ourServicesHeader g-0 ms-0">

                            {navbarByLang.body.ourServices.header}
                            {/*Xidmətlərimiz*/}
                        </p>
                    </Row>
                    <Row className="ourServicesAccordions ">
                        <MDBAccordion
                            style={{paddingLeft: "5px"}}
                            // onChange={changeChevronsTo90Degree}
                            borderless="true"
                            initialActive={0}>
                            <MDBAccordionItem
                                collapseId={1}
                                onClick={() => {
                                    changeChevronPosition(1)
                                }}

                                headerTitle={
                                    <>
                                        {/*{color:'#C13130',transition: 'all  0.5s ease-out'}*/}
                                        <MDBIcon
                                            className={`chevron ${!isChevronRight1 ? 'chevronDownTransact' : ''}`}
                                            // rotate={isChevronRight1 && '90'}
                                            // style={isChevronRight1
                                            //     ? transitionForChevron
                                            //     : {...transitionForChevron, ...{transform: 'rotate(90deg)'}}}
                                            fas icon="chevron-right"/> &nbsp; {navbarByLang.body.ourServices.data.ourServicesHead1}
                                    </>
                                }>
                                {navbarByLang.body.ourServices.data.ourServicesHead1Text}
                            </MDBAccordionItem>
                            <MDBAccordionItem
                                collapseId={2}
                                className="border-0"
                                onClick={() => {
                                    changeChevronPosition(2)
                                }}
                                headerTitle={
                                    <>
                                        <MDBIcon
                                            className={`chevron ${!isChevronRight2 ? 'chevronDownTransact' : ''}`}
                                            // style={isChevronRight2
                                            //     ? transitionForChevron
                                            //     : {...transitionForChevron, ...{transform: 'rotate(90deg)'}}}
                                            fas icon="chevron-right"/> &nbsp; {navbarByLang.body.ourServices.data.ourServicesHead2}
                                    </>
                                }>
                                {navbarByLang.body.ourServices.data.ourServicesHead2Text}
                            </MDBAccordionItem>

                            <MDBAccordionItem
                                collapseId={5}
                                className="border-0"
                                onClick={
                                    () => {
                                        changeChevronPosition(5)

                                    }}
                                headerTitle={
                                    <>
                                        <MDBIcon
                                            className={`chevron ${!isChevronRight5 ? 'chevronDownTransact' : ''}`}
                                            // style={isChevronRight5? transitionForChevron: {...transitionForChevron, ...{transform: 'rotate(90deg)'}}}
                                            fas icon="chevron-right"/>
                                        &nbsp; {navbarByLang.body.ourServices.data.ourServicesHead5}
                                    </>
                                }>
                                <p style={{textAlign:"justify"}}>
                                    {navbarByLang.body.ourServices.data.ourServicesHead5Text}
                                </p>
                                {/*<p>*/}
                                {/*    <Image style={{width: "103px", height: "21px"}}*/}
                                {/*           src={imgDownloadFileBtn}/>*/}
                                {/*</p>*/}
                            </MDBAccordionItem>
                        </MDBAccordion>

                    </Row>
                </Container>
            </div>
            {/*ourPaertners*/
            }
            <div style={{background: "#F7F8F9", marginTop: "20px", height: "auto"}}>
                <Container className="partners">
                    <div className="partnersHeader pt-4 mb-3">
                        <p className="headerTextSize">
                            {navbarByLang.body.partners.header}
                            {/*Tərəfdaşlarımız*/}
                        </p>
                    </div>
                    <Container className="partnersLogos g-0">
                        <Row className="
                            d-flex flex-wrap flex-row
                            align-content-start
                            justify-content-start
                            row-gap-2

                            column-gap-2
                            g-2 pb-3"
                             style={{
                                 backgroundColor: "#F7F8F9",
                                 width: "auto",
                                 minHeight: "104px",
                             }}>
                            {/*<Col>*/}
                            <Image className="iconPartners" style={{width: "203px", height: "102px", cursor: "pointer", borderRadius:'8px'}}
                                   src={imgMedicSchool}/>
                            {/*</Col>*/}
                            {/*<Col>*/}
                            {/*<Image className="iconPartners" style={{width: "203px", height: "102px", cursor: "pointer"}}*/}
                            {/*       src={imgITS}/>*/}
                            {/*</Col>*/}
                            {/*<Col>*/}
                            {/*<Image className="iconPartners" style={{width: "203px", height: "102px", cursor: "pointer"}}*/}
                            {/*       src={imgAAC}/>*/}
                            {/*</Col>*/}
                            {/*<Col>*/}
                            {/*<Image className="iconPartners" style={{width: "203px", height: "102px", cursor: "pointer"}}*/}
                            {/*       src={imgMega}/>*/}
                            {/*</Col>*/}
                            {/*<Col>*/}
                            {/*<Image className="iconPartners" style={{width: "203px", height: "102px", cursor: "pointer"}}*/}
                            {/*       src={imgAtewgah}/>*/}
                            {/*</Col>*/}
                            {/*<Col>*/}
                            {/*<Image className="iconPartners" style={{width: "203px", height: "102px", cursor: "pointer"}}*/}
                            {/*       src={imgPasha}/>*/}
                            {/*</Col>*/}
                        </Row>
                    </Container>
                </Container>
            </div>
            {/*aboutUs*/
            }
            <Container id="aboutUs" className={`d-flex flex-column justify-content-start ${style.aboutUs} mb-4`}>
                <Container className={`${style.aboutUsHeader}`}>
                    <p className={`${style.headerTextSize}`}
                       style={{marginBottom: "20px", marginTop: "20px"}}>
                        {navbarByLang.body.aboutUs.header}
                        {/*Haqqımızda*/}
                    </p>
                </Container>
                <Container className={`${style.aboutUsDescription} g-0`}>
                    <p className="mx-3" style={{maxWidth: "1290px", width: "auto", minHeight: "90px", height: "auto"}}>
                        {navbarByLang.body.aboutUs.description}
                    </p>
                </Container>
                {/*<Container style={{}}*/}
                {/*           className={`*/}
                {/*               ${style.aboutUsCardsWrapper}*/}
                {/*               g-0*/}
                {/*               `}>*/}
                {/*    {threeDoctorsForAboutUs}*/}
                {/*</Container>*/}

            </Container>
            {/*contactUs*/
            }
            <Container id="contactUs" className={`contactUs`}>
                <Row className={`${style.contactUsHeader}`}>
                    <p className={`${style.headerTextSize}`} style={{marginBottom: "30px"}}>
                        {navbarByLang.body.contacts.header}
                        {/*Əlaqə*/}
                    </p>
                </Row>
                <Row className={`${style.contactUsRow}`}>
                    <Col xs={12} sm={6} md={6} xl={4} className={`${style.contactUsRowCol1_Col1}`}>
                        <div className={`${style.contactUsSosials}`}>
                            <Image style={{width: "220px", height: "60px", marginBottom: "30px"}}
                                   src={imgSosialsALL}/>
                        </div>
                        <div className={`${style.contactUsContacts}`}>
                            <p className="g-0 m-0">
                                <MDBIcon style={{color: 'black', marginRight: "5px"}}
                                         fas icon="phone"/>
                                <span className={`${style.contactUsInfoSize1}`}>
                +994 70 266 65 10
                </span>
                            </p>
                            <p className="g-0 m-0">
                                <MDBIcon style={{color: 'black', marginRight: "5px"}}
                                         far icon="envelope"/>
                                <span className={`${style.contactUsInfoSize1}`}>
                info@saglikci.az
                </span>
                            </p>
                            <p className="g-0 m-0">
                                <MDBIcon style={{color: 'black', marginRight: "5px"}}
                                         fas icon="map-marker-alt"/>
                                <span className={`${style.contactUsInfoSize1}`}>
                Baku, Azərbaycan
                </span>
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} xl={4} className={`${style.contactUsCol} mt-5`}>
                        <div className={`${style.contactUsHeader}`}>
                            <h3>{navbarByLang.body.contacts.workTimeHeader}</h3>
                        </div>
                        <div className={`${style.contactUsInfos}`}>
                            <p className={`${style.contactUsInfoSize2} m-0 p-0`}>{navbarByLang.body.contacts.workTime}</p>
                            <p className={`${style.contactUsInfoSize2} m-0 p-0`}>{navbarByLang.body.contacts.description}</p>
                            <p className={`${style.contactUsInfoSize2} m-0 p-0`}>{navbarByLang.body.contacts.description2nd}</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={4} className={`${style.contactUsRow_Col2} mt-5 mb-5`}>
                        <Col className={`${style.contactUsCol2Downloads}
                            `}>
                            <Image src={isPortraitMinSize ? imgDownloadAppMobile : imgPlayStore}
                                   style={
                                       isPortraitMinSize
                                           ?
                                           {
                                               width: "135px",
                                               height: "90px",
                                               cursor: "pointer"
                                           }
                                           :
                                           {
                                               width: "410px",
                                               height: "130px",
                                               marginBottom: "5px",
                                               cursor: "pointer"
                                           }

                                   }/>
                        </Col>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default Web;


{/*<InputGroup style={isTabletOrMobile*/}
{/*    ? {minwidth: "300px", height: "50px", marginRight: '10px'}*/}
{/*    : {width: "360px", height: "50px", marginRight: '20px'}}*/}
{/*            className={`${isDesktopOrLaptop && 'ml-3'} shadow-0`}>*/}
{/*    <InputGroup.Text style={{*/}
{/*        borderLeft: '1px solid #CDCDCD',*/}
{/*        borderTop: '1px solid #CDCDCD',*/}
{/*        borderRight: '0px solid #CDCDCD',*/}
{/*        borderBottom: '1px solid #CDCDCD',*/}
{/*        borderRadius: '10px 0px 0px 10px',*/}
{/*        height: "50px"*/}
{/*    }}*/}
{/*                     id="basic-addon1">*/}
{/*        <svg width="22" height="22" viewBox="0 0 22 22" fill="none"*/}
{/*             xmlns="http://www.w3.org/2000/svg">*/}
{/*            <path fillRule="evenodd" clipRule="evenodd"*/}
{/*                  d="M9.78565 2.20691e-08C8.2251 0.000132966 6.68721 0.373443 5.30028 1.08878C3.91336 1.80413 2.71761 2.84076 1.81282 4.11219C0.90802 5.38362 0.320406 6.85298 0.0989992 8.39769C-0.122407 9.94239 0.028814 11.5176 0.540047 12.992C1.05128 14.4664 1.9077 15.7972 3.03786 16.8732C4.16801 17.9493 5.53913 18.7396 7.03682 19.178C8.5345 19.6165 10.1153 19.6904 11.6474 19.3937C13.1795 19.0969 14.6184 18.4381 15.8441 17.4722L20.0488 21.6768C20.2659 21.8865 20.5568 22.0026 20.8587 22C21.1605 21.9973 21.4493 21.8763 21.6628 21.6628C21.8762 21.4493 21.9973 21.1606 22 20.8587C22.0026 20.5568 21.8865 20.266 21.6768 20.0489L17.4721 15.8443C18.6096 14.4013 19.3179 12.6671 19.5158 10.8404C19.7138 9.01364 19.3934 7.16806 18.5914 5.51487C17.7894 3.86168 16.5382 2.46766 14.9809 1.49236C13.4236 0.51706 11.6232 -0.000123355 9.78565 2.20691e-08ZM2.30187 9.78611C2.30187 7.80137 3.09034 5.89791 4.49382 4.49448C5.8973 3.09105 7.80083 2.30261 9.78565 2.30261C11.7705 2.30261 13.674 3.09105 15.0775 4.49448C16.481 5.89791 17.2694 7.80137 17.2694 9.78611C17.2694 11.7709 16.481 13.6743 15.0775 15.0777C13.674 16.4812 11.7705 17.2696 9.78565 17.2696C7.80083 17.2696 5.8973 16.4812 4.49382 15.0777C3.09034 13.6743 2.30187 11.7709 2.30187 9.78611Z"*/}
{/*                  fill="#CDCDCD"/>*/}
{/*        </svg>*/}
{/*    </InputGroup.Text>*/}
{/*    <Form.Control style={{*/}
{/*        borderRight: '1px solid #CDCDCD',*/}
{/*        borderLeft: '0px solid #CDCDCD',*/}
{/*        borderTop: '1px solid #CDCDCD',*/}
{/*        borderBottom: '1px solid #CDCDCD',*/}
{/*        borderRadius: '0px 10px 10px 0px',*/}
{/*        height: "50px"*/}
{/*    }}*/}
{/*                  placeholder={navbarByLang.header.searchPlaceholder}*/}
{/*                  aria-label="search"*/}
{/*                  aria-describedby="basic-addon1"*/}
{/*    />*/}
{/*</InputGroup>*/}